import {
  changedUrlPath,
  setInitialUrl,
  setSearchParams
} from '../modules/url/actions'
import { track } from 'utils/analytics'

export default (store) => (next) => (action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      // Want to make sure that the location has changed
      // before calling trackHistory
      next(action)

      trackHistory(store)
      break

    default:
      next(action)
  }
}

function clearUrlSearchParams() {
  // eslint-disable-next-line no-undef
  const url = window.location.href

  // eslint-disable-next-line no-undef
  window.history.replaceState(
    {},
    // eslint-disable-next-line no-undef
    document.title,
    url.substring(0, url.indexOf('?'))
  )
}

function trackPageView() {
  // eslint-disable-next-line no-undef
  window.analytics.page()

  // Get the campaign info (if any) in the url
  // eslint-disable-next-line no-undef
  const campaignInfo = window.location.search.match(
    /(\?|&)uc([0-9A-Za-z]+)=[0-9A-Za-z]+/g
  )

  if (campaignInfo && campaignInfo.length > 0) {
    for (const campaignString of campaignInfo) {
      const [campaign, uniqueId] = campaignString
        .replace(/[&/?]+/g, '')
        .split('=')

      track('campaign_link_visited', {
        campaign,
        uniqueId,
        // eslint-disable-next-line no-undef
        url: window.location
      })
    }
  }
}

function trackHistory(store) {
  const state = store.getState().url

  // Track page view before any url stripping
  trackPageView()

  if (!state.landingUrl) {
    // eslint-disable-next-line no-undef
    const landingUrl = window.location.href
    const searchParams = new URL(landingUrl).searchParams

    store.dispatch(
      setInitialUrl(landingUrl, searchParams || new URLSearchParams())
    )

    // eslint-disable-next-line no-undef
    if (window.location.search) {
      clearUrlSearchParams()
    }
    // eslint-disable-next-line no-undef
  } else if (state.searchParams && window.location.search) {
    // eslint-disable-next-line no-undef
    const searchParams = new URL(window.location.href).searchParams

    for (const key of state.searchParams.keys()) {
      searchParams.append(key, state.searchParams.get(key))
    }

    store.dispatch(setSearchParams(searchParams))

    clearUrlSearchParams()
  }
  // eslint-disable-next-line no-undef
  store.dispatch(changedUrlPath(window.location.pathname))
}
