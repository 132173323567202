import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import COLORS from 'components/Toolkit/colors'
import DownChevron from 'components/Toolkit/Icons/DownChevron'
import Label from 'components/Toolkit/Text/Label'

const StyledDropdown = styled.div`
  background: #ffffff;
  border: 1px solid ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.NEUTRALS.OYSTER};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  cursor: pointer;
  padding: 10px 15px 10px 27px;
`

const StyledHiddenSelect = styled.select`
  position: absolute;
  margin: 0 4px;
  opacity: 0.01;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  cursor: pointer;
`

const StyledDropdownWrapper = styled.div`
  position: relative;
  padding-bottom: ${(props) => (props.hasValues ? '15px' : '0')};
`

const StyledChevronWrapper = styled.div`
  position: absolute;
  right: 20px;
`

const StyledInputContainer = styled.div`
  margin: ${(props) => (props.hasLabel ? '25px 0' : '')};
`

function Dropdown({ label, onChange = () => null, options = [], value = '' }) {
  const selectedItem = options.find(
    (item) => (item.value !== undefined ? item.value : item) === value
  )
  return (
    <StyledInputContainer>
      {label && <Label>{label}</Label>}
      <StyledDropdownWrapper>
        <StyledDropdown>
          <div>
            {(selectedItem &&
              (selectedItem.value !== undefined
                ? selectedItem.label
                : selectedItem)) ||
              '- Select -'}
          </div>

          <StyledChevronWrapper>
            <DownChevron width='9px' height='5px' />
          </StyledChevronWrapper>
        </StyledDropdown>
        <StyledHiddenSelect
          onChange={(event) => {
            onChange(event.target.value)
          }}
          value={value}
        >
          <option disabled value=''>
            - Select -
          </option>
          {options.map((item) => (
            <option
              key={item.value !== undefined ? item.value : item}
              value={item.value !== undefined ? item.value : item}
            >
              {item.label !== undefined ? item.label : item}
            </option>
          ))}
        </StyledHiddenSelect>
      </StyledDropdownWrapper>
    </StyledInputContainer>
  )
}

Dropdown.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired
}

export default memo(Dropdown)
