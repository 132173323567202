import React, { Component } from 'react'
import GoogleAnalytics from 'react-ga'

GoogleAnalytics.initialize('UA-99057972-3')

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    GoogleAnalytics.set({
      page,
      ...options
    })
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      console.log('dev, not sending GA pageview', page)
    } else {
      GoogleAnalytics.pageview(page)
    }
  }

  const HOC = class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname
      trackPage(page)
      // eslint-disable-next-line no-undef
      window.scrollTo(0, 0)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return HOC
}

export default withTracker
