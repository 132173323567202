import React, { memo, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import BackArrow from 'components/Toolkit/Icons/BackArrow'
import ProgressBar from 'components/Toolkit/ProgressBar'

const TYPE_TO_COLOR = {
  intro: COLORS.LIGHT_NEUTRALS.CORNFLOWER,
  default: COLORS.LIGHT_NEUTRALS.GARLIC,
  none: '#fff'
}

const StyledContainer = styled.div`
  min-height: 86vh;
  padding-bottom: 70px;

  @media (max-width: 750px) {
    padding: 29px;
    min-height: initial;
  }
`

const StyledContentWrapper = styled.div`
  margin: 0 auto 143px auto;
  display: flex;
  max-width: 512px;
  overflow: hidden;

  @media (max-width: 750px) {
    display: block;
  }
`

const StyledArrowContainer = styled.div`
  cursor: pointer;
  margin-right: 40px;
  margin-bottom: 20px;
`

const StyledFixedFooter = styled.div`
  background: ${COLORS.PRIMARY.COCONUT};
  border: 1px solid #eeedea;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 143px;
  z-index: 10;
`

const StyledFixedFooterInnerContainer = styled.div`
  padding: 26px 36px;
`

const StyledChildren = styled.div`
  width: 100%;
`

function ContentContainer({
  type = 'default',
  goBack = null,
  fixedFooter = null,
  progress = null,
  shouldScrollDown = false,
  onScrollDown = false,
  children
}) {
  const containerRef = useRef(null)

  const scrollToBottom = () => {
    if (shouldScrollDown) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      onScrollDown()
    }
  }
  useEffect(scrollToBottom, [shouldScrollDown])

  return (
    <div
      style={{
        background: TYPE_TO_COLOR[type],
        borderTop: '1px solid #EEEDEA'
      }}
      ref={containerRef}
    >
      <StyledContainer type={type}>
        <div style={{ marginBottom: '40px' }}>
          {progress ? <ProgressBar {...progress} /> : null}
        </div>

        <StyledContentWrapper type={type}>
          {goBack && (
            <StyledArrowContainer onClick={goBack}>
              <BackArrow color='#BFBDBB' />
            </StyledArrowContainer>
          )}
          <StyledChildren>{children}</StyledChildren>
        </StyledContentWrapper>
        {fixedFooter && (
          <StyledFixedFooter>
            <StyledFixedFooterInnerContainer>
              {fixedFooter}
            </StyledFixedFooterInnerContainer>
          </StyledFixedFooter>
        )}
      </StyledContainer>
    </div>
  )
}

ContentContainer.propTypes = {
  type: PropTypes.oneOf(['default', 'intro', 'none']),
  goBack: PropTypes.func,
  fixedFooter: PropTypes.node,
  progress: PropTypes.object
}

export default memo(ContentContainer)
