import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset, reduxForm, Field } from 'redux-form'
import { Form, FormGroup, Button, Alert } from 'reactstrap'
import each from 'lodash/each'
import trim from 'lodash/trim'

import {
  required,
  phoneNumber,
  normalizePhone,
  renderField
} from '../../utils/forms'
import '../../styles/auth-modal.css'
import styled from 'styled-components'

const StyledH4 = styled.h4`
  text-align: center;
  margin-bottom: 2rem;
`

class PhoneForm extends Component {
  static propTypes = {
    reset: PropTypes.func.isRequired,
    onPhoneSubmit: PropTypes.func.isRequired,
    phoneError: PropTypes.string
  }

  state = {
    submitting: false,
    error: false
  }

  UNSAFE_componentWillMount() {
    if (this.props.phoneError) {
      this.setState({ error: this.props.phoneError })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.phoneError) {
      this.setState({ error: nextProps.phoneError })
    }
  }

  submit = async (data) => {
    each(data, (value, key) => {
      data[key] = trim(value)
    })

    this.props.onPhoneSubmit(data.phone)
  }

  render() {
    const { valid, handleSubmit } = this.props

    return (
      <>
        {this.state.error && <Alert color='danger'>{this.state.error}</Alert>}
        <StyledH4>Please enter your phone number to continue.</StyledH4>
        <Form onSubmit={handleSubmit(this.submit)}>
          <FormGroup>
            <Field
              validate={[required, phoneNumber]}
              component={renderField}
              normalize={normalizePhone}
              placeholder='Phone'
              type='text'
              name='phone'
            />
          </FormGroup>

          <Button
            className='auth-form-submit-button'
            disabled={!valid || this.state.submitting}
            type='submit'
          >
            Continue
          </Button>
        </Form>
      </>
    )
  }
}

const mapDispatchToProps = {
  reset: () => reset('phoneForm')
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'phoneForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })(PhoneForm)
)
