import * as Sentry from '@sentry/react'

import axios from 'utils/axios'
import {
  RECEIVED_FIREBASE_USER,
  receivedHasuraToken,
  CLEAR_AUTH_USER
} from 'modules/auth/actions'
import { RECEIVED_USER_COOKIE } from 'modules/session/actions'
import { servicingApi } from 'config'
import { Dispatch } from 'redux'

const worker = (store: any) => (next: any) => (action: any) => {
  switch (action.type) {
    case CLEAR_AUTH_USER:
    case RECEIVED_USER_COOKIE:
    case RECEIVED_FIREBASE_USER:
      next(action)
      getHasuraCreds(store.dispatch)
      break

    default:
      next(action)
  }
}

export default worker

export const getHasuraCreds = async (
  dispatch: Dispatch<any>
): Promise<void> => {
  try {
    const {
      data: { credentials }
    } = await axios.post<any, any>(`${servicingApi}/hasura/auth`, {})
    dispatch(receivedHasuraToken(credentials))
  } catch (err) {
    Sentry.captureException(err)
    dispatch(receivedHasuraToken(null))
  }
}
