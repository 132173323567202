import React from 'react'
import { clearToast } from 'modules/toast/actions'
import Toast from 'components/Toolkit/Toast'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

function ToastWrapper({ toast, clearToast }) {
  return (
    <div>
      {toast && (
        <Toast
          id={toast.id}
          text={toast.text}
          icon={toast.icon}
          action={toast.action}
          duration={toast.duration}
          clear={clearToast}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  toast: state.toast
})

const mapDispatchToProps = {
  clearToast
}

ToastWrapper.propTypes = {
  toast: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastWrapper)
