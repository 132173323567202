import React, { memo, useState } from 'react'

import BodyText from 'components/Toolkit/Text/Body'
import DownChevron from 'components/Toolkit/Icons/DownChevron'
import UpChevron from 'components/Toolkit/Icons/UpChevron'

function Toggle({
  label = '',
  children,
  isCloseable = true,
  defaultIsClosed = false
}) {
  const [isClosed, setIsClosed] = useState(defaultIsClosed)

  return (
    <div style={{ marginBottom: '-1px' }}>
      <hr
        style={{
          margin: 0,
          borderTop: '1px solid #EEE',
          visibility: isClosed ? 'visible' : 'hidden'
        }}
      />

      <div
        style={{
          margin: '20px 0'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <BodyText
            style={{
              color: '#55545A',
              fontWeight: '600',
              opacity: 0.5
            }}
            size='s'
          >
            {label}
          </BodyText>
          {isCloseable ? (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => setIsClosed(!isClosed)}
            >
              {isClosed ? <UpChevron /> : <DownChevron />}
            </span>
          ) : null}
        </div>

        {!isClosed && children}
      </div>

      {isClosed ? (
        <hr
          style={{
            margin: 0,
            borderTop: '1px solid #EEE',
            visibility: isClosed ? 'visible' : 'hidden'
          }}
        />
      ) : null}
    </div>
  )
}

export default memo(Toggle)
