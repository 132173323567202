import React, { memo, useState, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import * as Sentry from '@sentry/react'
import { track } from 'utils/analytics'

import firebase from 'utils/firebase'

const storageRef = firebase.storage().ref()

function UploadContainer({
  children,
  uploadPath = '',
  onChange = () => null,
  onSuccess = () => null
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [downloadUrl, setDownloadUrl] = useState(null)

  function clearData() {
    if (file) {
      URL.revokeObjectURL(file.preview)
    }
  }

  const resetState = () => {
    setFile(null)
    setIsLoading(false)
    setError(null)
    setDownloadUrl(null)
  }

  useEffect(() => {
    resetState()
    return () => clearData()
  }, [uploadPath])

  useEffect(() => {
    onChange({
      isLoading,
      file,
      error,
      downloadUrl,
      uploadPath,
      resetState
    })
  }, [isLoading, file, error, downloadUrl])

  const onDrop = async (acceptedFiles) => {
    resetState()
    setIsLoading(true)

    const acceptedFile = acceptedFiles[0]

    setFile(
      Object.assign(acceptedFile, {
        preview: URL.createObjectURL(acceptedFile)
      })
    )

    if (uploadPath) {
      const upload = storageRef.child(uploadPath).put(acceptedFile, {
        contentType: acceptedFile.type
      })

      upload.on(
        'state_changed',
        // Progress
        () => null,
        // Error
        () => {
          setIsLoading(false)
          setError('Error uploading document')
          Sentry.captureMessage(`Error uploading document: ${file.path}`)
          track('rental_application.document_upload_error', {
            children,
            uploadPath,
            isLoading,
            error,
            downloadUrl,
            file: file.path
          })
        },
        // Success
        async () => {
          const downloadUrl = await upload.snapshot.ref.getDownloadURL()
          setIsLoading(false)
          setDownloadUrl(downloadUrl)
          onSuccess(downloadUrl)
        }
      )
    }
  }

  return (
    <Dropzone multiple={false} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
          >
            <input {...getInputProps()} />
            {React.cloneElement(children, {
              isLoading,
              downloadUrl,
              remove: resetState,
              error
            })}
          </div>
        )
      }}
    </Dropzone>
  )
}

export default memo(UploadContainer)
