import { SET_NOTIFICATION_IS_VISIBLE } from './actions'

export default function reducer(
  state = {
    isNotificationVisible: false
  },
  action
) {
  switch (action.type) {
    case SET_NOTIFICATION_IS_VISIBLE:
      return {
        isNotificationVisible: action.payload
      }
    default:
      return state
  }
}
