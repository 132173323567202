import * as Sentry from '@sentry/react'

import axios from '../../utils/axios'
import { servicingApi } from '../../config'

export const CLEAR_AUTH_USER = 'CLEAR_AUTH_USER'
export const RECEIVED_AUTH_USER = 'RECEIVED_AUTH_USER'
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL'
export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL'
export const RECEIVED_FIREBASE_USER = 'RECEIVED_FIREBASE_USER'
export const RECEIVED_HASURA_TOKEN = 'RECEIVED_HASURA_TOKEN'

export const receivedAuthUser = (authUser) => {
  if (authUser) {
    authUser.email = authUser.email2 || authUser.email
    authUser.phone = authUser.phone2 || authUser.phone
    // eslint-disable-next-line no-undef
    window.analytics.identify(authUser.id, {
      name: `${authUser.first_name} ${authUser.last_name}`,
      email: authUser.email
    })
    Sentry.setUser({ id: authUser.id, email: authUser.email })
  }

  return {
    type: RECEIVED_AUTH_USER,
    authUser
  }
}

export const receivedFirebaseUser = (firebaseUser) => ({
  type: RECEIVED_FIREBASE_USER,
  firebaseUser
})

export const receivedHasuraToken = (token) => ({
  type: RECEIVED_HASURA_TOKEN,
  token
})

export const closeAuthModal = (success) => {
  return (dispatch, getState) => {
    if (success) {
      const onSuccess = getState().auth.onSuccess
      onSuccess && onSuccess()
    }

    dispatch({ type: CLOSE_AUTH_MODAL })
  }
}

export const openAuthModal = (options) => ({
  type: OPEN_AUTH_MODAL,
  options
})

export const createOrFetchUser = (idToken) => {
  return async (dispatch) => {
    const res = await axios.post(`${servicingApi}/users/create_or_fetch`, {
      firebase_id_token: idToken
    })

    const user = res.data

    dispatch(receivedAuthUser(user))

    return user
  }
}

export const retrieveUpdatedUser = () => {
  return async (dispatch) => {
    const { data: user } = await axios.get(`${servicingApi}/users/me`)
    dispatch(receivedAuthUser(user))
  }
}

export const createUser = (idToken) => {
  return () => {
    return axios.post(`${servicingApi}/users/create_or_fetch`, {
      firebase_id_token: idToken
    })
  }
}

export const updateUser = (update) => {
  return async (dispatch) => {
    try {
      const res = await axios.post(`${servicingApi}/users`, update)

      const user = res.data

      dispatch(receivedAuthUser(user))

      return user
    } catch (err) {
      throw new Error(err.response.data.message)
    }
  }
}

export const clearAuthUser = () => {
  return async (dispatch) => {
    Sentry.configureScope((scope) => scope.setUser(null))

    dispatch({
      type: CLEAR_AUTH_USER
    })
  }
}
