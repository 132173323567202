import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import Label from 'components/Toolkit/Text/Label'

// Just a random note, HTML doesn't support treating the date text as a placeholder
// So having the placeholder color and then then input color didn't make sense
const StyledInput = styled.input`
  background: ${COLORS.PRIMARY.COCONUT};
  border: ${(props) =>
    props.hasError
      ? `1.5px solid ${COLORS.ACCENTS.SALMON}`
      : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  box-sizing: border-box;
  border-radius: 5px;
  color: ${(props) =>
    props.type === 'date' ? COLORS.LIGHT_NEUTRALS.PARSNIP : '#263135'}
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 12px;
  transition: border 200ms;
  width: 100%;

  &::placeholder {
    color: ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  }

  &:focus {
    border: ${(props) =>
      props.hasError
        ? `1.5px solid ${COLORS.ACCENTS.SALMON}`
        : `1px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  }
`

const StyledInputContainer = styled.div`
  margin: ${(props) => (props.hasLabel ? '25px 0' : '')};
`

function Input({ label, value, onChange, type, hasError, ...otherProps }) {
  return (
    <StyledInputContainer hasLabel={Boolean(label)}>
      {label && <Label>{label}</Label>}
      <StyledInput
        type={type}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        hasError={hasError}
        {...otherProps}
      />
    </StyledInputContainer>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool
}

export default memo(Input)
