import React, { Component } from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

import '../../styles/auth-modal.css'

export default class ThirdPartyAuthButton extends Component {
  static propTypes = {
    signIn: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  }

  render() {
    return (
      <Button
        className='third-party-auth-button'
        onClick={this.props.signIn}
        style={{
          backgroundColor: this.props.backgroundColor,
          color: this.props.color
        }}
      >
        <span className='third-party-auth-button-icon-wrapper'>
          <img
            className='third-party-auth-button-icon'
            alt=''
            src={this.props.img}
          />
        </span>
        <span className='third-party-auth-button-text'>{this.props.text}</span>
      </Button>
    )
  }
}
