import debounce from 'lodash/debounce'

import axios from '../../utils/axios'
import { servicingApi } from '../../config'
import { track } from 'utils/analytics'

export const TOGGLE_SEARCH_OPEN = 'NEIGHBORHOODS/TOGGLE_SEARCH_OPEN'
export const SET = 'NEIGHBORHOODS/SET'
export const TOGGLE_ID_SELECTION = 'NEIGHBORHOODS/TOGGLE_SELECTION'
export const CLEAR_ALL_SELECTIONS = 'NEIGHBORHOODS/CLEAR_ALL_SELECTIONS'
export const OPEN_NEIGHBORHOOD = 'NEIGHBORHOODS/OPEN_NEIGHBORHOOD'
export const FILTER = 'NEIGHBORHOODS/FILTER'
export const SET_FILTER_TEXT = 'NEIGHBORHOODS/SET_FILTER_TEXT'
export const SET_NEIGHBORHOOD_SELECTIONS =
  'NEIGHBORHOODS/SET_NEIGHBORHOOD_SELECTIONS'

const ST_LOUIS_COUNTY_IDS = [
  '101716fc-8e6c-43ee-a6bd-9503f7ea5de2',
  '7dce80a8-137d-4303-9308-26a85b136629'
]

export function getNeighborhoods() {
  return async (dispatch, getState) => {
    const { neighborhoods } = getState()

    if (!neighborhoods.length) {
      const neighborhoods = await axios.get(
        `${servicingApi}/neighborhood_hierarchies`,
        {
          params: {
            county_id: ST_LOUIS_COUNTY_IDS
          }
        }
      )

      track('neighborhood_search.loaded_data')

      dispatch({
        type: SET,
        payload: neighborhoods.data
      })
    } else {
      dispatch(clearNeighborhoodSelections())
    }
  }
}

export function toggleNeighborhoodSelection(id) {
  track('neighborhood_search.toggle_neighborhood')

  return {
    type: TOGGLE_ID_SELECTION,
    payload: id
  }
}

export function setNeighborhoodSelections(ids) {
  track('neighborhood_search.clicked_save')

  return {
    type: SET_NEIGHBORHOOD_SELECTIONS,
    payload: ids
  }
}

export function openNeighborhood(neighborhood) {
  track('neighborhood_search.show_sub_neighborhoods', neighborhood)

  return {
    type: OPEN_NEIGHBORHOOD,
    payload: neighborhood
  }
}

export function clearNeighborhoodSelections() {
  return {
    type: CLEAR_ALL_SELECTIONS
  }
}

export function toggleNeighborhoodSearchOpen() {
  track('neighborhood_search.toggle_open')

  return {
    type: TOGGLE_SEARCH_OPEN
  }
}

const filter = debounce((dispatch, text) => {
  track('neighborhood_search.filter_neighborhoods', {
    text
  })

  dispatch({
    type: FILTER,
    payload: text
  })
}, 300)

export function filterNeighborhoods(text) {
  return async (dispatch, getState) => {
    const {
      neighborhoods: { fuseInit }
    } = getState()

    if (fuseInit) {
      filter(dispatch, text)
      dispatch({
        type: SET_FILTER_TEXT,
        payload: text
      })
    }
  }
}
