import {
  SET_SIGNUP_FORM,
  RECEIVED_USER_COOKIE,
  RECEIVED_COOKIE_USER,
  DONE_LOADING_COOKIE_USER,
  DONE_LOADING_AUTH_USER
} from './actions'

export default function reducer(
  state = {
    doneLoadingUser: false,
    doneLoadingAuthUser: false,
    doneLoadingCookieUser: false,
    signUpForm: null,
    userCookie: null,
    cookieUser: null
  },
  action
) {
  switch (action.type) {
    case RECEIVED_COOKIE_USER:
      if (!state.cookieUser && action.cookieUser && action.cookieUser.id) {
        // eslint-disable-next-line no-undef
        window.analytics.identify(action.cookieUser.id, {
          name: `${action.cookieUser.firstName} ${action.cookieUser.lastName}`,
          email: action.cookieUser.email
        })
      }

      return {
        ...state,
        cookieUser: action.cookieUser,
        doneLoadingCookieUser: true,
        doneLoadingUser: state.doneLoadingAuthUser || false
      }
    case RECEIVED_USER_COOKIE:
      return {
        ...state,
        userCookie: action.userCookie
      }
    case SET_SIGNUP_FORM:
      return {
        ...state,
        signUpForm: action.signUpForm
      }
    case DONE_LOADING_COOKIE_USER:
      return {
        ...state,
        doneLoadingCookieUser: true,
        doneLoadingUser: state.doneLoadingAuthUser || false
      }
    case DONE_LOADING_AUTH_USER:
      return {
        ...state,
        doneLoadingAuthUser: true,
        doneLoadingUser: state.doneLoadingCookieUser || false
      }
    default:
      return state
  }
}
