const phone = '3144515459'
const fullPhone = '+1' + phone
const displayPhone = `(${phone.substring(0, 3)}) ${phone.substring(
  3,
  6
)}-${phone.substring(6)}`
const dashPhone = `${phone.substring(0, 3)}-${phone.substring(
  3,
  6
)}-${phone.substring(6)}`

exports = { phone, fullPhone, displayPhone, dashPhone }
