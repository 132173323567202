import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  ModalFooter
} from 'reactstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import startCase from 'lodash/startCase'
import get from 'lodash/get'

import ThirdPartyAuthButton from './ThirdPartyAuthButton'
import RegisterForm from './RegisterForm'
import LoginForm from './LoginForm'
import InitialDetailsForm from './InitialDetailsForm'
import PhoneForm from './PhoneForm'
import firebase from '../../utils/firebase'
import '../../styles/auth-modal.css'
import {
  closeAuthModal,
  createOrFetchUser,
  updateUser
} from '../../modules/auth/actions'
import { onlyNums, normalizePhone } from '../../utils/forms'
import ResetPasswordEmailForm from './ResetPasswordEmailForm'
import { isMissingRequiredUserInfo } from '../../utils/users'

const StyledBold = styled.b`
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
    color: #00cfcc;
  }
`

const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider()

class AuthModal extends Component {
  static propTypes = {
    closeAuthModal: PropTypes.func.isRequired,
    createOrFetchUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    longSignUpTitle: PropTypes.string,
    shortSignUpTitle: PropTypes.string,
    longSignInTitle: PropTypes.string,
    shortSignInTitle: PropTypes.string,
    initialView: PropTypes.string,
    toggleDisabled: PropTypes.bool.isRequired,
    authUser: PropTypes.object,
    cookieUser: PropTypes.object
  }

  static defaultProps = {
    longSignUpTitle: 'Get started',
    longSignInTitle: 'Sign in',
    shortSignUpTitle: 'Get started',
    shortSignInTitle: 'Sign in',
    initialState: 'register'
  }

  state = {
    isSignIn: false,
    error: false,
    askForDetails: false,
    askForPhone: false,
    toggle: this.closeAuthModal,
    initialDetails: {
      phone: '+1 '
    },
    phoneError: null,
    detailsError: null,
    showPasswordReset: false
  }

  UNSAFE_componentWillMount() {
    if (this.props.authUser) {
      const firebaseUser = firebase.auth().currentUser
      this.checkForMissingDetails(firebaseUser)
    }
    this.setState({
      isSignIn: this.props.initialView === 'signIn',
      toggle: this.props.toggleDisabled ? undefined : this.closeAuthModal
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.authUser && !this.props.authUser) {
      const firebaseUser = firebase.auth().currentUser
      this.checkForMissingDetails(firebaseUser)
    } else if (!newProps.authUser && this.props.authUser) {
      // Reset the state on logout
      this.setState({
        isSignIn: false,
        error: false,
        askForDetails: false,
        askForPhone: false,
        toggle: this.closeAuthModal,
        initialDetails: {},
        showPasswordReset: false
      })
    }

    if (this.props.initialView !== newProps.initialView) {
      this.setState({
        isSignIn: newProps.initialView === 'signIn'
      })
    }

    if (newProps.toggleDisabled && !this.props.toggleDisabled) {
      this.setState({
        toggle: undefined
      })
    } else if (!newProps.toggleDisabled && this.props.toggleDisabled) {
      this.setState({
        toggle: this.closeAuthModal
      })
    }
  }

  closeAuthModal = () => {
    this.props.closeAuthModal(false)
  }

  thirdPartySignIn = async (authProvider) => {
    if (this.state.error) {
      this.setState({ error: false })
    }

    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      await firebase.auth().signInWithPopup(authProvider)

      const firebaseUser = firebase.auth().currentUser

      this.checkForMissingDetails(firebaseUser)
    } catch (err) {
      // Ignore the firebase modal closed error
      if (
        err.message !==
        'The popup has been closed by the user before finalizing the operation.'
      ) {
        this.setState({ error: err.message })
      }
    }
  }

  googleSignIn = () => {
    this.thirdPartySignIn(googleAuthProvider)
  }

  facebookSignIn = () => {
    this.thirdPartySignIn(facebookAuthProvider)
  }

  toggleSignInState = () => {
    this.setState({ isSignIn: !this.state.isSignIn })
  }

  checkForMissingDetails = async (firebaseUser) => {
    const { email, displayName } = firebaseUser

    let firstName
    let lastName

    if (displayName) {
      const indexOfSpace = displayName.indexOf(' ')
      firstName = startCase(displayName.substring(0, indexOfSpace))
      lastName = startCase(displayName.substring(indexOfSpace + 1))
    }

    if (isMissingRequiredUserInfo(email, firstName, lastName)) {
      this.setState({
        askForDetails: true,
        toggle: null,
        initialDetails: {
          email,
          firstName,
          lastName
        }
      })
    } else {
      const idToken = await firebaseUser.getIdToken()

      try {
        const user = await this.props.createOrFetchUser(idToken)

        if (!user.phone) {
          this.setState({
            askForPhone: true,
            toggle: null
          })
        } else {
          this.props.closeAuthModal(true)
        }
      } catch (e) {
        this.setState({ detailsError: 'That email is already in use.' })
      }
    }
  }

  phoneProvided = async (phone) => {
    const formattedNumber = `+${onlyNums(phone)}`

    const update = {
      phone: formattedNumber
    }
    try {
      await this.props.updateUser(update)

      this.setState({
        askForPhone: false
      })

      this.props.closeAuthModal(true)
    } catch (e) {
      this.setState({
        phoneError: 'That phone number is already in use.'
      })
    }
  }

  termsSection = (className) => {
    return (
      <div className={className}>
        By continuing, I agree to Up&Up's <br />
        <Link className='terms-link' to='/privacy'>
          PRIVACY POLICY
        </Link>{' '}
        and{' '}
        <Link className='terms-link' to='/terms'>
          TERMS OF SERVICE
        </Link>
      </div>
    )
  }

  orDivider = (className) => {
    return <div className={className}>Or</div>
  }

  closePasswordReset = () => {
    this.setState({ showPasswordReset: false })
  }

  showPasswordReset = () => {
    this.setState({ showPasswordReset: true })
  }

  render() {
    const {
      longSignInTitle,
      longSignUpTitle,
      shortSignInTitle,
      shortSignUpTitle,
      show,
      cookieUser
    } = this.props

    return (
      <Modal isOpen={show} centered size='lg' toggle={this.state.toggle}>
        <ModalHeader
          toggle={this.state.toggle}
          className={this.state.toggle ? '' : 'no-toggle'}
        >
          {this.state.isSignIn ? (
            <div>
              <span className='title-long'>{longSignInTitle}</span>
              <span className='title-short'>{shortSignInTitle}</span>
            </div>
          ) : (
            <div>
              <span className='title-long'>{longSignUpTitle}</span>
              <span className='title-short'>{shortSignUpTitle}</span>
            </div>
          )}
        </ModalHeader>
        <ModalBody>
          <Container style={{ padding: '0 1rem' }}>
            {!this.state.askForDetails &&
              !this.state.askForPhone &&
              !this.state.showPasswordReset && (
                <>
                  {this.orDivider('left-or')}
                  <Row style={{ margin: '0 -1rem' }}>
                    <Col sm='12' md='6' style={{ padding: '0 1rem' }}>
                      <div className='third-party-auth-col-wrapper'>
                        {this.state.error && (
                          <Alert color='danger'>{this.state.error}</Alert>
                        )}

                        <ThirdPartyAuthButton
                          signIn={this.googleSignIn}
                          text={
                            this.state.isSignIn
                              ? 'Sign in with Google'
                              : 'Sign up with Google'
                          }
                          img='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg'
                          backgroundColor='#fff'
                          color='#757575'
                        />
                        <ThirdPartyAuthButton
                          signIn={this.facebookSignIn}
                          text={
                            this.state.isSignIn
                              ? 'Sign in with Facebook'
                              : 'Sign up with Facebook'
                          }
                          img='https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg'
                          backgroundColor='#3b5998'
                          color='#fff'
                        />

                        {this.state.isSignIn ? (
                          <p className='not-member left'>
                            Not an Up&Up member?{' '}
                            <StyledBold onClick={this.toggleSignInState}>
                              SIGN UP
                            </StyledBold>
                          </p>
                        ) : (
                          <p className='already-member left'>
                            Already a member?{' '}
                            <StyledBold onClick={this.toggleSignInState}>
                              SIGN IN
                            </StyledBold>
                          </p>
                        )}

                        {this.termsSection('terms-section left')}
                      </div>
                    </Col>
                    <Col
                      sm='12'
                      md='6'
                      className='left-divider'
                      style={{ padding: '0 1rem' }}
                    >
                      {this.orDivider('top-or sign-up')}
                      <div className='auth-col-wrapper'>
                        {this.state.isSignIn ? (
                          <>
                            <LoginForm
                              checkForMissingDetails={
                                this.checkForMissingDetails
                              }
                            />
                            <div className='forgot-password'>
                              <span onClick={this.showPasswordReset}>
                                Forgot Password?
                              </span>
                            </div>
                          </>
                        ) : (
                          <RegisterForm
                            initialValues={{
                              first_name: get(cookieUser, 'firstName'),
                              last_name: get(cookieUser, 'lastName'),
                              email: get(cookieUser, 'email'),
                              phone_number:
                                normalizePhone(get(cookieUser, 'phone')) ||
                                '+1 '
                            }}
                            closeAuthModal={this.props.closeAuthModal}
                          />
                        )}
                      </div>

                      {this.state.isSignIn ? (
                        <p className='not-member'>
                          Not an Up&Up member?{' '}
                          <StyledBold onClick={this.toggleSignInState}>
                            SIGN UP
                          </StyledBold>
                        </p>
                      ) : (
                        <p className='already-member'>
                          Already a member?{' '}
                          <StyledBold onClick={this.toggleSignInState}>
                            SIGN IN
                          </StyledBold>
                        </p>
                      )}

                      {this.termsSection('terms-section bottom')}
                    </Col>
                  </Row>
                </>
              )}
            {this.state.askForDetails && (
              <Row>
                <Col xs={12}>
                  <InitialDetailsForm
                    initialValues={this.state.initialDetails}
                    detailsError={this.state.detailsError}
                  />
                </Col>
              </Row>
            )}
            {this.state.askForPhone && (
              <Row>
                <Col xs={12}>
                  <PhoneForm
                    initialValues={{ phone: '+1 ' }}
                    onPhoneSubmit={this.phoneProvided}
                    phoneError={this.state.phoneError}
                  />
                </Col>
              </Row>
            )}
            {this.state.showPasswordReset && (
              <Row>
                <Col xs={12}>
                  <ResetPasswordEmailForm goBack={this.closePasswordReset} />
                </Col>
              </Row>
            )}
          </Container>
        </ModalBody>
        <ModalFooter />
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  longSignInTitle: state.auth.longSignInTitle,
  longSignUpTitle: state.auth.longSignUpTitle,
  shortSignInTitle: state.auth.shortSignInTitle,
  shortSignUpTitle: state.auth.shortSignUpTitle,
  initialView: state.auth.initialView,
  show: state.auth.show,
  toggleDisabled: state.auth.modalToggleDisabled,
  authUser: state.auth.authUser,
  cookieUser: state.session.cookieUser
})

const mapDispatchToProps = {
  createOrFetchUser,
  closeAuthModal,
  updateUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal)
