import React, { memo } from 'react'

function Pencil({ color = '#19778D', height = '16', width = '16' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.0954 3.95994C15.4131 3.6422 15.4131 3.11265 15.0954 2.81121L13.189 0.904804C12.8875 0.58707 12.358 0.58707 12.0402 0.904804L10.5412 2.39571L13.5963 5.45084L15.0954 3.95994ZM0.666992 12.278V15.3332H3.72212L12.7327 6.31442L9.67759 3.25929L0.666992 12.278Z'
        fill={color}
      />
    </svg>
  )
}

export default memo(Pencil)
