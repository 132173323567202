export function formatName(s) {
  if (!s) {
    return s
  }

  const words = s.split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase()
  }

  return words.join(' ')
}
