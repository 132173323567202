import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AsyncComponent from './components/AsyncComponent'
import AuthRequired from './components/AuthRequired'
import withTracker from './modules/analytics/gaWithTracker'

const AsyncProjections = withTracker(
  AsyncComponent(() => import('./screens/Projections'))
)
const AsyncRSRedirect = withTracker(
  AsyncComponent(() => import('./components/RSRedirect'))
)

const AuthAccount = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() => import('./screens/Account'))}
  />
))
const AuthPreapproval = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() => import('./screens/PreApproval'))}
  />
))
const AuthApplication = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() => import('./screens/Application'))}
  />
))
const AuthJoinApplication = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() => import('./screens/JoinApplication'))}
  />
))
const AuthApplicationTimeline = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() => import('./screens/Application/Timeline'))}
  />
))
const AuthApplicationYourHome = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() => import('./screens/Application/YourHome'))}
  />
))
const AuthApplicationPayment = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() => import('./screens/Application/Payment'))}
  />
))
const AuthApplicationFinancialDocsQuestionnaire = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() =>
      import('./screens/Application/FinancialDocs/Questionnaire')
    )}
  />
))
const AuthApplicationYourInformation = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() =>
      import('./screens/Application/YourInformation')
    )}
  />
))
const AuthApplicationLease = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() =>
      import('./screens/Application/ReviewLease')
    )}
  />
))
const AuthSelfServeApplication = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() => import('./screens/SelfServeApplication'))}
  />
))
const AuthNewPaymentSource = withTracker((props) => (
  <AuthRequired
    {...props}
    component={AsyncComponent(() => import('./screens/NewPaymentSource'))}
  />
))
const AsyncUserFirebaseActions = withTracker(
  AsyncComponent(() => import('./screens/UserFirebaseActions'))
)
const AsyncDownloadApp = withTracker(
  AsyncComponent(() => import('./screens/DownloadApp'))
)
const AsyncPrivacy = withTracker(
  AsyncComponent(() => import('./screens/PrivacyView'))
)

const AsyncRetargeted = withTracker(
  AsyncComponent(() => import('./screens/Retargeted'))
)

const AsyncTourConfirmation = withTracker(
  AsyncComponent(() => import('./screens/TourConfirmation'))
)

const AsyncShortPropertyInteractionIdResolver = withTracker(
  AsyncComponent(() => import('./screens/ShortPropertyInteractionIdResolver'))
)

const Routes = () => (
  <Switch>
    <Route
      exact
      path='/'
      // eslint-disable-next-line no-undef
      component={() => {
        window.location.assign('https://app.upandup.co/homes')
        return null
      }}
    />

    <Route path='/privacy' exact component={AsyncPrivacy} />
    <Route path='/terms' exact component={AsyncPrivacy} />
    <Route path='/projections/:propertyShortId' component={AsyncProjections} />
    <Route path='/payment_sources' component={AuthNewPaymentSource} />

    <Route path='/rs/:source/:propertyShortId' component={AsyncRSRedirect} />

    <Route
      path='/account/payments'
      component={() => {
        window.location.assign('https://app.upandup.co/account/payments')
        return null
      }}
    />
    <Route path='/account/verify'>
      <Redirect to='/user/preapproval' />
    </Route>
    <Route path='/account/:route' component={AuthAccount} />
    <Route path='/account' component={AuthAccount} />
    <Route
      path='/user/preapproval/your-information'
      component={AuthApplicationYourInformation}
    />
    <Route path='/user/preapproval' component={AuthPreapproval} />
    <Route path='/user/payment' component={AuthApplicationPayment} />
    <Route path='/join/:rentalApplicationId' component={AuthJoinApplication} />
    <Route
      path='/user/application/:rentalApplicationId/your-home'
      component={AuthApplicationYourHome}
    />
    <Route
      path='/user/application/:rentalApplicationId/timeline'
      component={AuthApplicationTimeline}
    />
    <Route
      path='/user/application/:rentalApplicationId/your-information'
      component={AuthApplicationYourInformation}
    />
    <Route
      path='/user/application/:rentalApplicationId/financial-docs/questionnaire'
      component={AuthApplicationFinancialDocsQuestionnaire}
    />
    <Route
      path='/user/application/:rentalApplicationId/payment'
      component={AuthApplicationPayment}
    />
    <Route
      path='/user/application/:rentalApplicationId/lease'
      component={AuthApplicationLease}
    />
    <Route
      path='/user/application/:rentalApplicationId'
      component={AuthApplication}
    />
    <Route
      path='/user/apply/:propertyId'
      component={AuthSelfServeApplication}
    />

    <Redirect
      from='/homes-for-rent/:cityState/:addressZip/:propertyShortId/schedule'
      to='/homes/:cityState/:addressZip/:propertyShortId/schedule'
    />
    <Redirect
      from='/homes-for-rent/:cityState/:addressZip/:propertyShortId/download'
      to='/homes/:cityState/:addressZip/:propertyShortId/download'
    />
    <Redirect
      from='/homes-for-rent/:cityState/:addressZip/:propertyShortId'
      to='/homes/:cityState/:addressZip/:propertyShortId'
    />
    <Redirect from='/homes-for-rent/:cityState' to='/homes/:cityState' />
    <Redirect from='/homes-for-rent' to='/homes' />

    <Route path='/homes/fql' component={AsyncRetargeted} />

    <Route path='/tours/confirm' component={AsyncTourConfirmation} />

    <Route path='/actions' component={AsyncUserFirebaseActions} />

    <Route path='/download' component={AsyncDownloadApp} />

    <Route
      path='/pi/:shortId'
      component={AsyncShortPropertyInteractionIdResolver}
    />

    <Redirect to='/' />
  </Switch>
)

export default Routes
