import React, { memo } from 'react'
import COLORS from 'components/Toolkit/colors'

function DownChevron({
  width = '14',
  height = '8',
  color = COLORS.NEUTRALS.OYSTER
}) {
  return (
    <svg width={width} height={height} viewBox='0 0 14 8' fill='none'>
      <path
        d='M13.6317 0.355353C13.1427 -0.118451 12.3528 -0.118451 11.8639 0.355353L6.99921 5.0691L2.13456 0.355353C1.64558 -0.118451 0.855703 -0.118451 0.366731 0.355353C-0.122242 0.829157 -0.122242 1.59453 0.366731 2.06834L6.12157 7.64465C6.61054 8.11845 7.40042 8.11845 7.88939 7.64465L13.6442 2.06834C14.1207 1.60668 14.1207 0.829157 13.6317 0.355353Z'
        fill={color}
      />
    </svg>
  )
}

export default memo(DownChevron)
