import React, { memo } from 'react'

function Lock({ color = '#BFBDBB', height = '15px', width = '20px' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.58333 14.8017C8.05299 14.8017 8.50341 14.6178 8.8355 14.2903C9.1676 13.9629 9.35417 13.5188 9.35417 13.0557C9.35417 12.0867 8.55729 11.3097 7.58333 11.3097C7.11368 11.3097 6.66326 11.4936 6.33117 11.8211C5.99907 12.1485 5.8125 12.5926 5.8125 13.0557C5.8125 13.5188 5.99907 13.9629 6.33117 14.2903C6.66326 14.6178 7.11368 14.8017 7.58333 14.8017ZM12.8958 6.94461C13.3655 6.94461 13.8159 7.12856 14.148 7.45601C14.4801 7.78345 14.6667 8.22756 14.6667 8.69064V17.4208C14.6667 17.8839 14.4801 18.328 14.148 18.6554C13.8159 18.9829 13.3655 19.1668 12.8958 19.1668H2.27083C1.80118 19.1668 1.35076 18.9829 1.01866 18.6554C0.686569 18.328 0.5 17.8839 0.5 17.4208V8.69064C0.5 7.72159 1.29687 6.94461 2.27083 6.94461H3.15625V5.19858C3.15625 4.04088 3.62267 2.93061 4.45291 2.112C5.28315 1.29339 6.4092 0.833496 7.58333 0.833496C8.16471 0.833496 8.74039 0.946402 9.2775 1.16577C9.81462 1.38513 10.3027 1.70666 10.7138 2.112C11.1248 2.51733 11.4509 2.99854 11.6734 3.52813C11.8959 4.05773 12.0104 4.62534 12.0104 5.19858V6.94461H12.8958ZM7.58333 2.57953C6.87885 2.57953 6.20322 2.85546 5.70508 3.34663C5.20694 3.8378 4.92708 4.50396 4.92708 5.19858V6.94461H10.2396V5.19858C10.2396 4.50396 9.95973 3.8378 9.46159 3.34663C8.96344 2.85546 8.28781 2.57953 7.58333 2.57953Z'
        fill={color}
      />
    </svg>
  )
}

export default memo(Lock)
