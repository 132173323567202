import React, { memo } from 'react'

function BackChevron({ width = '11', height = '17', color = '#000' }) {
  return (
    <svg width={width} height={height} viewBox='0 0 11 17' fill='none'>
      <path
        d='M10.2435 2.72097C10.6556 2.32686 10.6553 1.66851 10.2428 1.27485L9.59727 0.658851C9.21087 0.290101 8.60289 0.290102 8.21649 0.658851L0.758068 7.77656C0.345254 8.17052 0.345254 8.82948 0.758068 9.22344L8.21649 16.3411C8.60289 16.7099 9.21087 16.7099 9.59727 16.3411L10.2428 15.7251C10.6553 15.3315 10.6556 14.6731 10.2435 14.279L4.95671 9.22268C4.54494 8.82887 4.54495 8.17113 4.95671 7.77731L10.2435 2.72097Z'
        fill={color}
      />
    </svg>
  )
}

export default memo(BackChevron)
