import homes from '../../data/homes'
import { RECEIVED_HOME } from './actions'

export default function reducer(state = homes, action) {
  switch (action.type) {
    case RECEIVED_HOME:
      return {
        ...state,
        [action.home.shortId]: action.home
      }
    default:
      return state
  }
}
