import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import COLORS from 'components/Toolkit/colors'
import DownChevron from 'components/Toolkit/Icons/DownChevron'

const StyledDropdown = styled.div`
  background: #ffffff;
  border: 1px solid ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${COLORS.NEUTRALS.OYSTER};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  cursor: pointer;
  padding: 10px 15px 10px 27px;
`

const StyledHiddenSelect = styled.select`
  position: absolute;
  margin: 0 4px;
  opacity: 0.01;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  cursor: pointer;
`

const StyledDropdownWrapper = styled.div`
  position: relative;
  flex: 1 1 0px;
`

const StyledDurationWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledChevronWrapper = styled.div`
  position: absolute;
  right: 20px;
`

const StyledTo = styled.div`
  margin: 0 17px;
  color: ${COLORS.NEUTRALS.OYSTER};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  width: 20px;
  text-align: center;
`

function getValuesBetween(start, end, step) {
  const values = []

  for (let i = start; i <= end; i = i + step) {
    values.push(i)
  }

  return values
}

function Range({
  onChange = () => null,
  min = 1,
  max = 10,
  step = 1,
  lastEntry = null, // Use for last entries like $5,000+
  startValue = null,
  endValue = null,
  displayFormat = () => null
}) {
  const possibleStartValues = getValuesBetween(min, max, step)
  const possibleEndValues = getValuesBetween(startValue || min, max, step)

  // We want to allow for last values like $5,000+ which actually contain a value of much greater
  // So in order to display the proper text we need to massage the value a bit
  let displayEndValue = ''

  if (lastEntry && lastEntry.value === endValue) {
    displayEndValue = lastEntry.text
  } else if (endValue) {
    displayEndValue = displayFormat(endValue)
  } else {
    displayEndValue = '- Select -'
  }

  return (
    <div>
      <StyledDurationWrapper>
        <StyledDropdownWrapper>
          <StyledDropdown>
            <div>{startValue ? displayFormat(startValue) : '- Select -'}</div>

            <StyledChevronWrapper>
              <DownChevron width='9px' height='5px' />
            </StyledChevronWrapper>
          </StyledDropdown>
          <StyledHiddenSelect
            onChange={(event) => {
              const newStart = parseInt(event.target.value)
              onChange({
                start: newStart || 0,
                end:
                  newStart > endValue
                    ? lastEntry
                      ? lastEntry.value
                      : 0
                    : endValue
              })
            }}
            value={startValue === null ? '' : startValue.toString()}
          >
            <option disabled value=''>
              - Select -
            </option>
            {possibleStartValues.map((value, idx) => (
              <option key={idx} value={value}>
                {displayFormat(value)}
              </option>
            ))}
          </StyledHiddenSelect>
        </StyledDropdownWrapper>

        <StyledTo>to</StyledTo>
        <StyledDropdownWrapper>
          <StyledDropdown>
            <div>{displayEndValue}</div>
            <StyledChevronWrapper>
              <DownChevron width='9px' height='5px' />
            </StyledChevronWrapper>
          </StyledDropdown>
          <StyledHiddenSelect
            onChange={(event) =>
              onChange({
                start: startValue,
                end: parseInt(event.target.value) || 0
              })
            }
            value={endValue === null ? '' : endValue.toString()}
          >
            <option disabled value=''>
              - Select -
            </option>
            {possibleEndValues.map((value, idx) => (
              <option key={idx} value={value}>
                {displayFormat(value)}
              </option>
            ))}
            {lastEntry ? (
              <option value={lastEntry.value}>{lastEntry.text}</option>
            ) : null}
          </StyledHiddenSelect>
        </StyledDropdownWrapper>
      </StyledDurationWrapper>
    </div>
  )
}

Range.propTypes = {
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  lastEntry: PropTypes.object,
  startValue: PropTypes.number.isRequired,
  endValue: PropTypes.number.isRequired,
  displayFormat: PropTypes.func.isRequired
}

export default memo(Range)
