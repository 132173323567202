import pick from 'lodash/pick'
import { firestoreCollections } from 'utils/firebase'

export const SET_SIGNUP_FORM = 'SET_SIGNUP_FORM'
export const RECEIVED_USER_COOKIE = 'RECEIVED_USER_COOKIE'
export const RECEIVED_COOKIE_USER = 'RECEIVED_COOKIE_USER'
export const DONE_LOADING_COOKIE_USER = 'SESSION/DONE_LOADING_COOKIE_USER'
export const DONE_LOADING_AUTH_USER = 'SESSION/DONE_LOADING_AUTH_USER'

export const updateFirestoreUser = (newValues, id) => {
  return async (dispatch, getState) => {
    const {
      auth: { firebaseUser },
      session: { cookieUser }
    } = getState()

    const user = firebaseUser || cookieUser
    if (id || user) {
      await firestoreCollections.usersRef.doc(id || user.id).update(newValues)
    }
  }
}

export function setSignUpForm(signUpForm) {
  return (dispatch) => {
    dispatch({
      type: SET_SIGNUP_FORM,
      signUpForm
    })
  }
}

export const receivedUserCookie = (userCookie, noTracking = false) => ({
  type: RECEIVED_USER_COOKIE,
  userCookie,
  noTracking
})

export const receivedCookieUser = (cookieUser) => ({
  type: RECEIVED_COOKIE_USER,
  cookieUser
})

export const doneLoadingCookieUser = () => ({
  type: DONE_LOADING_COOKIE_USER
})

export const doneLoadingAuthUser = () => ({
  type: DONE_LOADING_AUTH_USER
})

export function getUserCookie() {
  return async (dispatch) => {
    // eslint-disable-next-line no-undef
    const userCookie = JSON.parse(localStorage.getItem('userCookie'), {})
    dispatch(receivedUserCookie(userCookie))
  }
}

export function updateUserCookie(update, postUpdate = null) {
  return async (dispatch, getState) => {
    const {
      url: { searchParams },
      session: { userCookie }
    } = getState()

    const newCookie = Object.assign({}, userCookie || {}, update)

    if (!searchParams || !searchParams.get('no_events')) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('userCookie', JSON.stringify(newCookie))
    }

    dispatch(receivedUserCookie(newCookie))

    if (postUpdate) {
      postUpdate()
    }
  }
}

export function clearUserCookie() {
  return async () => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('userCookie', JSON.stringify({}))
  }
}

export function setScheduleForm(scheduleForm) {
  return (dispatch, getState) => {
    if (!getState().session.signUpForm) {
      const signUpValuesFromScheduleForm = pick(scheduleForm, [
        'firstName',
        'lastName',
        'tel',
        'email',
        'movingDate',
        'credit',
        'income',
        'location'
      ])

      // eslint-disable-next-line no-undef
      localStorage.setItem(
        'signUpForm',
        JSON.stringify(signUpValuesFromScheduleForm)
      )

      dispatch(setSignUpForm(signUpValuesFromScheduleForm))
    }
  }
}
