import short from 'short-uuid'

import { servicingApi } from 'config'

import axios from 'utils/axios'

export const RECEIVED_HOME = 'RECEIVED_HOME'

const translator = short()

export function receivedHome(home) {
  return {
    type: RECEIVED_HOME,
    home
  }
}

export function fetchHome(shortId) {
  return async (dispatch) => {
    const propertyId = translator.toUUID(shortId)

    const { data: property } = await axios.get(
      `${servicingApi}/firestore/properties/${propertyId}`
    )

    if (property) {
      dispatch(receivedHome(property))

      return property
    } else {
      return false
    }
  }
}
