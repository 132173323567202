import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset, reduxForm, Field } from 'redux-form'
import { Form, FormGroup, Button, Row, Col, Alert } from 'reactstrap'
import trim from 'lodash/trim'
import each from 'lodash/each'
import { formatName } from 'utils/string'

import firebase from '../../utils/firebase'
import {
  required,
  registerEmail,
  phoneNumber,
  normalizePhone,
  renderField,
  onlyNums
} from '../../utils/forms'
import { createUser, updateUser } from '../../modules/auth/actions'
import '../../styles/auth-modal.css'

class RegisterForm extends Component {
  static propTypes = {
    reset: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    closeAuthModal: PropTypes.func.isRequired
  }

  state = {
    submitting: false,
    error: false
  }

  submit = async (data) => {
    this.setState({
      submitting: true
    })

    each(data, (value, key) => {
      data[key] = trim(value)
    })

    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      await firebase
        .auth()
        .createUserWithEmailAndPassword(data.email.toLowerCase(), data.password)

      const firebaseUser = firebase.auth().currentUser

      const first_name = formatName(data.first_name)
      const last_name = formatName(data.last_name)

      await firebaseUser.updateProfile({
        displayName: `${first_name} ${last_name}`
      })

      const idToken = await firebaseUser.getIdToken()

      await this.props.createUser(idToken)

      const phone = `+${onlyNums(data.phone_number)}`

      await this.props.updateUser({ phone })

      if (!firebaseUser.emailVerified) {
        firebaseUser.sendEmailVerification()
      }

      this.props.closeAuthModal(true)
    } catch (err) {
      this.setState({
        error: err.message,
        submitting: false
      })
    }
  }

  render() {
    const { valid, handleSubmit } = this.props

    return (
      <>
        {this.state.error && <Alert color='danger'>{this.state.error}</Alert>}
        <Form onSubmit={handleSubmit(this.submit)}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Field
                  validate={[required]}
                  component={renderField}
                  placeholder='First name'
                  type='text'
                  name='first_name'
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Field
                  validate={[required]}
                  component={renderField}
                  placeholder='Last name'
                  type='text'
                  name='last_name'
                />
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Field
              validate={[required, registerEmail]}
              component={renderField}
              placeholder='Email'
              type='email'
              name='email'
            />
          </FormGroup>

          <FormGroup>
            <Field
              validate={[required, phoneNumber]}
              component={renderField}
              placeholder='Phone number'
              normalize={normalizePhone}
              type='tel'
              name='phone_number'
            />
          </FormGroup>

          <FormGroup>
            <Field
              validate={[required]}
              component={renderField}
              placeholder='Password'
              type='password'
              name='password'
            />
          </FormGroup>

          <Button
            type='submit'
            className='auth-form-submit-button'
            disabled={!valid || this.state.submitting}
            onClick={handleSubmit(this.submit)}
          >
            Get started
          </Button>
        </Form>
      </>
    )
  }
}

const mapDispatchToProps = {
  reset: () => reset('registerForm'),
  createUser,
  updateUser
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'registerForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })(RegisterForm)
)
