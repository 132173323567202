import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { openAuthModal } from '../../modules/auth/actions'
import NavBar from '../NavBar'

class AuthRequired extends Component {
  static propTypes = {
    component: PropTypes.func.isRequired,
    authBootstrapped: PropTypes.bool.isRequired,
    user: PropTypes.object
  }

  UNSAFE_componentWillMount() {
    if (this.props.authBootstrapped) {
      this.handleAuthentication(this.props)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      (!this.props.authBootstrapped && newProps.authBootstrapped) ||
      !this.props.user !== newProps.user
    ) {
      this.handleAuthentication(newProps)
    }
  }

  handleAuthentication(props) {
    if (!props.user) {
      this.props.openAuthModal({
        initialView: 'signIn',
        longSignInTitle: 'Sign in to continue',
        modalToggleDisabled: true
      })
    }
  }

  render() {
    if (this.props.user) {
      const { component: Component } = this.props

      return <Component {...this.props} user={this.props.user} />
    } else {
      return <NavBar />
    }
  }
}

const mapStateToProps = (state) => ({
  authBootstrapped: state.auth.isBootstrapped,
  user: state.auth.authUser
})

const mapDispatchToProps = {
  openAuthModal
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRequired)
