import { SET_IS_PROCESSING, SET_IS_COMPLETE, UPDATE_EMAIL } from './actions'

const DEFAULT_STATE = {
  isProcessing: false,
  isComplete: false,
  email: ''
}

export default function reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_IS_PROCESSING:
      return {
        ...state,
        ...{
          isProcessing: true
        }
      }
    case SET_IS_COMPLETE:
      return {
        ...state,
        ...{
          isComplete: true
        }
      }
    case UPDATE_EMAIL:
      return {
        ...state,
        ...{
          email: action.payload
        }
      }
    default:
      return state
  }
}
