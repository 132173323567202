import React, { memo } from 'react'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'

const PADDINGS = {
  r: '17px 34px',
  s: '10px 24px'
}

const FONT_SIZE = {
  r: '18px',
  s: '16px'
}

const StyledButton = styled.button`
  color: ${(props) =>
    props.isSecondary ? COLORS.PRIMARY.PLUM : COLORS.PRIMARY.COCONUT};
  background: ${(props) =>
    props.isSecondary ? COLORS.PRIMARY.COCONUT : COLORS.PRIMARY.JELLO};
  border: ${(props) =>
    props.isSecondary ? `1px solid ${COLORS.PRIMARY.PLUM}` : '0'};
  border-radius: 100px;
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => FONT_SIZE[props.size]};
  line-height: 18px;
  padding: ${(props) => PADDINGS[props.size]};
  transition: background 200ms;

  &&& {
    outline: 0;
    user-select: none;
  }

  &:focus {
    border: ${(props) =>
      props.isSecondary ? `1px solid ${COLORS.PRIMARY.PLUM}` : '0'};
  }

  &:disabled {
    border: 0;
    color: ${COLORS.PRIMARY.COCONUT};
    background: ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  }
`

const StyledLink = styled.a`
  color: ${(props) =>
    props.isSecondary ? COLORS.PRIMARY.PLUM : COLORS.PRIMARY.COCONUT};
  background: ${(props) =>
    props.isSecondary ? COLORS.PRIMARY.COCONUT : COLORS.PRIMARY.JELLO};
  border: ${(props) =>
    props.isSecondary ? `1px solid ${COLORS.PRIMARY.PLUM}` : '0'};
  border-radius: 100px;
  display: inline-block;
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => FONT_SIZE[props.size]};
  line-height: 18px;
  padding: ${(props) => PADDINGS[props.size]};
  transition: background 200ms;

  &&& {
    outline: 0;
    user-select: none;
  }

  &:hover {
    color: ${(props) =>
      props.isSecondary ? COLORS.PRIMARY.PLUM : COLORS.PRIMARY.COCONUT};
  }

  &:focus {
    border: ${(props) =>
      props.isSecondary ? `1px solid ${COLORS.PRIMARY.PLUM}` : '0'};
  }

  &:disabled {
    border: 0;
    color: ${COLORS.PRIMARY.COCONUT};
    background: ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  }
`

function Button({ isSecondary, isLink = false, size = 'r', ...otherProps }) {
  // This provides a better native way to open up links and not get caught by popup blockers
  return isLink ? (
    <StyledLink size={size} isSecondary={isSecondary} {...otherProps} />
  ) : (
    <StyledButton size={size} isSecondary={isSecondary} {...otherProps} />
  )
}

export default memo(Button)
