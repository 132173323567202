import { RECEIVED_AUTH_USER, CLEAR_AUTH_USER } from '../modules/auth/actions'
import { firestoreCollections } from '../utils/firebase'
import { receivedUserTasks } from '../modules/user-tasks/actions'

export default (store) => (next) => (action) => {
  switch (action.type) {
    case RECEIVED_AUTH_USER:
      next(action)

      if (action.authUser) {
        startSyncingUserTasks(store, action.authUser.id)
      }

      break

    case CLEAR_AUTH_USER:
      next(action)
      stopSyncingUserTasks()
      break

    default:
      next(action)
  }
}

let unsubscribe

export function startSyncingUserTasks(store, userId) {
  unsubscribe = firestoreCollections.userTasksRef
    .where('userId', '==', userId)
    .where('status', '==', 'pending_customer')
    .onSnapshot(async (userTasksQuery) => {
      const userTasks = new Map()

      userTasksQuery.docs.forEach((doc) => {
        const task = doc.data()

        if (!task.completed) {
          userTasks.set(task.id, task)
        }
      })

      store.dispatch(receivedUserTasks(userTasks))
    })
}

export function stopSyncingUserTasks() {
  unsubscribe && unsubscribe()
}
