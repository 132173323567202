export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
}

export const plaidConfig = {
  publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
  env: process.env.REACT_APP_PLAID_ENV
}

export const servicingApi = process.env.REACT_APP_SERVICING_API
export const facebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID
export const calendlyBookingUrl = process.env.REACT_APP_CALENDLY_BOOKING_URL
export const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN
