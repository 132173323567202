import axios from 'utils/axios'
import { servicingApi } from 'config'
import { updateUserCookie } from 'modules/session/actions'

export const SET_IS_PROCESSING = 'REGION_COMING_SOON/SET_IS_PROCESSING'
export const SET_IS_COMPLETE = 'REGION_COMING_SOON/SET_IS_COMPLETE'
export const UPDATE_EMAIL = 'REGION_COMING_SOON/UPDATE_EMAIL'

export const createContact = () => {
  return async (dispatch, getState) => {
    const {
      regionComingSoon: { email }
    } = getState()

    dispatch({
      type: SET_IS_PROCESSING
    })

    const userData = {
      email
    }

    try {
      await axios.post(`${servicingApi}/contacts/upsert`, userData)
      dispatch(updateUserCookie(userData))
    } catch (e) {
      // We don't really care if it fails because we already have all their info
    }

    dispatch({
      type: SET_IS_COMPLETE
    })
  }
}

export const updateEmail = (email) => ({
  type: UPDATE_EMAIL,
  payload: email
})
