import {
  RECEIVED_FIREBASE_USER,
  RECEIVED_AUTH_USER,
  OPEN_AUTH_MODAL,
  CLOSE_AUTH_MODAL,
  CLEAR_AUTH_USER,
  RECEIVED_HASURA_TOKEN
} from './actions'

const defaultState = {
  show: false,
  initialView: undefined, // signIn, register
  onSuccess: undefined,
  longSignInTitle: undefined,
  shortSignInTitle: undefined,
  longSignUpTitle: undefined,
  shortSignUpTitle: undefined,
  modalToggleDisabled: false
}

export default function reducer(
  state = {
    authUser: null,
    firebaseUser: null,
    isBootstrapped: false,
    ...defaultState
  },
  action
) {
  switch (action.type) {
    case RECEIVED_AUTH_USER:
      return {
        ...state,
        isBootstrapped: true,
        authUser: action.authUser
      }
    case RECEIVED_FIREBASE_USER:
      return {
        ...state,
        firebaseUser: action.firebaseUser
      }
    case RECEIVED_HASURA_TOKEN:
      return {
        ...state,
        hasuraToken: action.token
      }
    case CLEAR_AUTH_USER:
      return {
        ...state,
        ...defaultState,
        show: state.show,
        isBootstrapped: true,
        authUser: null,
        firebaseUser: null
      }
    case CLOSE_AUTH_MODAL:
      return {
        ...state,
        ...defaultState
      }
    case OPEN_AUTH_MODAL:
      return {
        ...state,
        ...action.options,
        show: true
      }
    default:
      return state
  }
}
