import axios from 'axios'

import firebase from '../utils/firebase'

const client = axios.create({})

function responseInterceptor(res) {
  // Log in development
  // eslint-disable-next-line no-undef
  if (!window.location.hostname.includes('upandup.co')) {
    const response = res instanceof Error ? res.response : res

    console.log(
      '[Axios] %s %s %s',
      response?.status,
      response?.config.method.toUpperCase(),
      response?.config.url
    )
    console.log('[Axios]', response)
  }

  // TODO if 401, pop auth modal

  return res instanceof Error ? Promise.reject(res) : res
}

client.interceptors.response.use(responseInterceptor, responseInterceptor)

client.interceptors.request.use(async (requestConfig) => {
  // Add authorization header for each request if we have received an admin
  if (!requestConfig.headers.Authorization && firebase.auth().currentUser) {
    const token = await firebase.auth().currentUser.getIdToken()

    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`
    }

    return requestConfig
  } else {
    return requestConfig
  }
})

export default client
