import { CHANGED_URL_PATH, SET_INITIAL_URL, SET_SEARCH_PARAMS } from './actions'

export default function reducer(
  state = {
    landingUrl: null,
    searchParams: null,
    history: []
  },
  action
) {
  switch (action.type) {
    case SET_INITIAL_URL:
      return {
        ...state,
        searchParams: action.searchParams,
        landingUrl: action.landingUrl
      }
    case SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.searchParams
      }
    case CHANGED_URL_PATH:
      return {
        ...state,
        history: [...state.history, action.path]
      }
    default:
      return state
  }
}
