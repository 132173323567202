import React, { memo } from 'react'

function CircleCheckmark() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <circle cx='9' cy='9' r='9' fill='#4DC0BF' />
      <path
        d='M6.77851 10.5146L5.08053 9.00633C4.77634 8.73613 4.29528 8.73613 3.99109 9.00633C3.66964 9.29187 3.66964 9.76737 3.99109 10.0529L6.23639 12.0473C6.54058 12.3176 7.02164 12.3176 7.32583 12.0473L13.0089 6.99924C13.3304 6.7137 13.3304 6.23819 13.0089 5.95265C12.7048 5.68248 12.2238 5.68245 11.9196 5.95257C11.9195 5.95259 11.9195 5.9526 11.9195 5.95262C11.9195 5.95263 11.9195 5.95264 11.9195 5.95265L6.77851 10.5146Z'
        fill='white'
        stroke='white'
        strokeWidth='0.5'
      />
    </svg>
  )
}

export default memo(CircleCheckmark)
