import { sortBy } from 'lodash'
import Fuse from 'fuse.js'

import {
  SET,
  TOGGLE_ID_SELECTION,
  CLEAR_ALL_SELECTIONS,
  OPEN_NEIGHBORHOOD,
  SET_NEIGHBORHOOD_SELECTIONS,
  TOGGLE_SEARCH_OPEN,
  FILTER,
  SET_FILTER_TEXT
} from './actions'

const DEFAULT_STATE = {
  data: [],
  noParent: [],
  byParentId: {},
  byId: {},
  selectedIds: [],
  openedNeighborhood: null,
  isSearchOpen: false,
  filterText: '',
  fuseInit: null,
  filterResults: [],
  hasFiltered: false
}

function groupNeighborhoodsByParentId(neighborhoods) {
  const structuredNeighborhoods = neighborhoods.reduce(
    (acc, neighborhood) => {
      const parentId = neighborhood.parent_id

      if (neighborhood.level === 1) {
        acc.noParent.push(neighborhood)
      } else if (parentId) {
        acc.byParentId[parentId] = acc.byParentId[parentId]
          ? acc.byParentId[parentId].concat([neighborhood])
          : [neighborhood]
      }

      return acc
    },
    {
      noParent: [],
      byParentId: {}
    }
  )

  return structuredNeighborhoods
}

export default function reducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET: {
      // Doing this to speed up things through less mem usage
      const filteredUnusedProps = action.payload.map((item) => ({
        id: item.id,
        name: item.name,
        parent_id: item.parent_id,
        level: item.level
      }))

      const { noParent, byParentId } =
        groupNeighborhoodsByParentId(filteredUnusedProps)

      return {
        ...state,
        ...{
          data: sortBy(filteredUnusedProps, [
            (neighborhood) => neighborhood.name
          ]),
          fuseInit: new Fuse(filteredUnusedProps, {
            keys: ['name'],
            threshold: 0.3
          }),
          noParent,
          byParentId,
          byId: filteredUnusedProps.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        }
      }
    }
    case TOGGLE_ID_SELECTION: {
      const newSelectedIds = state.selectedIds.includes(action.payload)
        ? state.selectedIds.filter((id) => id !== action.payload)
        : state.selectedIds.concat([action.payload])
      return {
        ...state,
        ...{
          selectedIds: newSelectedIds,
          filterText: '',
          filterResults: [],
          hasFiltered: false
        }
      }
    }
    case CLEAR_ALL_SELECTIONS:
      return {
        ...state,
        ...{
          selectedIds: []
        }
      }
    case OPEN_NEIGHBORHOOD:
      return {
        ...state,
        ...{
          openedNeighborhood: action.payload
        }
      }
    case SET_NEIGHBORHOOD_SELECTIONS:
      return {
        ...state,
        ...{
          openedNeighborhood: null,
          selectedIds: action.payload
        }
      }
    case TOGGLE_SEARCH_OPEN:
      return {
        ...state,
        ...{
          filterText: '',
          filterResults: [],
          isSearchOpen: !state.isSearchOpen,
          hasFiltered: false,
          openedNeighborhood: null
        }
      }
    case SET_FILTER_TEXT:
      return {
        ...state,
        ...{
          filterText: action.payload
        }
      }
    case FILTER:
      return {
        ...state,
        ...{
          filterResults: action.payload
            ? state.fuseInit.search(action.payload).map((item) => item.item)
            : [],
          hasFiltered: Boolean(action.payload)
        }
      }
    default:
      return state
  }
}
