import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { reducer as form } from 'redux-form'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'

// Reducers
import homes from './modules/homes/reducer'
import url from './modules/url/reducer'
import session from './modules/session/reducer'
import auth from './modules/auth/reducer'
import userTasks from './modules/user-tasks/reducer'
import neighborhoods from './modules/neighborhoods/reducer'
import regionComingSoon from './modules/region-coming-soon/reducer'
import pageLayout from './modules/page-layout/reducer'
import toast from './modules/toast/reducer'

import * as workers from './workers'

export const history = createBrowserHistory()

const appReducer = combineReducers({
  form,
  homes,
  url,
  session,
  auth,
  userTasks,
  neighborhoods,
  regionComingSoon,
  pageLayout,
  toast,
  router: connectRouter(history)
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

export default function rootReducer(state, action) {
  return appReducer(state, action)
}

const middlewares = [
  thunk,
  ...Object.values(workers),
  routerMiddleware(history)
].filter(Boolean)

// eslint-disable-next-line no-undef
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
)
