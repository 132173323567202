import axios from '../../utils/axios'

import { servicingApi } from '../../config'

export const RECEIVED_USER_TASKS = 'RECEIVED_USER_TASKS'

export const receivedUserTasks = (userTasks) => ({
  type: RECEIVED_USER_TASKS,
  userTasks
})

export const fetchUserTaskSignUrl = (signatureId) => {
  return () => {
    return axios
      .get(`${servicingApi}/hellosign/sign_url/${signatureId}`)
      .then((res) => res.data)
  }
}
