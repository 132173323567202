import React, { memo } from 'react'
import styled from 'styled-components'
import { sortBy } from 'lodash'

import COLORS from 'components/Toolkit/colors'
import BackLongArrow from 'components/Toolkit/Icons/BackLongArrow'
import XThinIcon from 'components/Toolkit/Icons/XThin'
import Checkbox from 'components/Toolkit/Inputs/Checkbox'
import HeaderText from 'components/Toolkit/Text/Header'

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  padding: 24px 33px;
`

const StyledHeaderText = styled(HeaderText)`
  font-weight: 500;
  margin: 0;
`

const StyledBody = styled.div`
  padding: 14px 33px;
  overflow-y: auto;
  flex: ${(props) => (props.growBody ? '1 0 0' : '')};
`

const StyledCheckbox = styled(Checkbox)`
  margin: 10px 0;
`

const StyledIcon = styled.span`
  cursor: pointer;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLowerLevelContainer = styled.div`
  margin-left: 35px;
`

function SelectionModal({
  parentItem,
  childrenItems,
  selectedIds,
  onSelect,
  onBack,
  onClose,
  growBody,
  ...extraProps
}) {
  return (
    <StyledContainer {...extraProps}>
      <StyledHeader>
        <StyledIcon onClick={onBack}>
          <BackLongArrow />
        </StyledIcon>
        <StyledHeaderText size='h4'>{parentItem.name}</StyledHeaderText>
        <StyledIcon onClick={onClose}>
          <XThinIcon width='17px' height='17px' />
        </StyledIcon>
      </StyledHeader>
      <StyledBody growBody={growBody}>
        <StyledCheckbox
          label={`All of ${parentItem.name}`}
          value={selectedIds.includes(parentItem.id)}
          onChange={() => onSelect(parentItem.id)}
        />
        <StyledLowerLevelContainer>
          {sortBy(childrenItems, [(item) => item.name]).map((item) => (
            <StyledCheckbox
              key={item.id}
              label={item.name}
              value={selectedIds.includes(item.id)}
              onChange={() => {
                onSelect(item.id)
              }}
            />
          ))}
        </StyledLowerLevelContainer>
      </StyledBody>
    </StyledContainer>
  )
}

export default memo(SelectionModal)
