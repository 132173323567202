import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import XThin from 'components/Toolkit/Icons/XThin'
import exit from './exit.png'

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999;
`

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 100vh;
  background: ${COLORS.PRIMARY.COCONUT};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999999;
  width: ${(props) => props.width};
  max-width: 95vw;

  @media (max-width: 527px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
`

const StyledHeader = styled.div`
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  overflow-x: hidden;
  overflow-y: hidden;

  text-align: center;

  color: ${COLORS.PRIMARY.PLUM};
  padding: 27px 32px;

  border-bottom: 1px solid ${COLORS.LIGHT_NEUTRALS.PARSNIP};
`

const StyledBody = styled.div`
  padding: 35px 32px;
  overflow-x: hidden;
  overflow-y: scrollable;

  @media (max-width: 527px) {
    flex-grow: 1;
  }
`

const StyledFooter = styled.div`
  border-top: 1px solid ${COLORS.LIGHT_NEUTRALS.PARSNIP};
  padding: 35px 32px;
  overflow-x: hidden;
  overflow-y: hidden;
`

const StyledCloseButton = styled.div`
  position: absolute;
  right: 30px;
  top: ${(props) => (props.isLower ? '35px' : '26px')};
  cursor: pointer;
`

function Modal({
  headerText,
  width = '416px',
  hasCloseButton,
  footer = null,
  close = () => null,
  children
}) {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.body.classList.add('modal-open')

    return () => {
      // eslint-disable-next-line no-undef
      document.body.classList.remove('modal-open')
    }
  }, [])
  return (
    <div>
      <StyledBackdrop onClick={close} />
      <StyledModal width={width}>
        {headerText ? (
          <StyledHeader>
            {headerText}
            {hasCloseButton ? (
              <StyledCloseButton onClick={close}>
                <XThin height='15px' width='15px' />
              </StyledCloseButton>
            ) : null}
          </StyledHeader>
        ) : null}
        {!headerText && hasCloseButton ? (
          <StyledCloseButton isLower onClick={close}>
            <img src={exit} />
          </StyledCloseButton>
        ) : null}
        <StyledBody>{children}</StyledBody>
        {footer ? <StyledFooter>{footer}</StyledFooter> : null}
      </StyledModal>
    </div>
  )
}

Modal.propTypes = {
  hasCloseButton: PropTypes.bool,
  footer: PropTypes.node,
  headerText: PropTypes.string
}

export default memo(Modal)
