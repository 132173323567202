import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset, reduxForm, Field } from 'redux-form'
import { Form, FormGroup, Button, Alert } from 'reactstrap'
import trim from 'lodash/trim'
import each from 'lodash/each'

import firebase from '../../utils/firebase'
import { required, email, renderField } from '../../utils/forms'
import { createOrFetchUser } from '../../modules/auth/actions'

import '../../styles/auth-modal.css'

class LoginForm extends Component {
  static propTypes = {
    reset: PropTypes.func.isRequired,
    createOrFetchUser: PropTypes.func.isRequired,
    checkForMissingDetails: PropTypes.func.isRequired
  }

  state = {
    submitting: false,
    error: false
  }

  submit = async (data) => {
    each(data, (value, key) => {
      data[key] = trim(value)
    })

    try {
      this.setState({ submitting: true, error: false })

      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      await firebase
        .auth()
        .signInWithEmailAndPassword(data.email.toLowerCase(), data.password)

      this.props.checkForMissingDetails(firebase.auth().currentUser)
    } catch (err) {
      this.setState({ error: err.message })
      this.setState({ submitting: false })
    }
  }

  render() {
    const { valid, handleSubmit } = this.props

    return (
      <>
        {this.state.error && <Alert color='danger'>{this.state.error}</Alert>}

        <Form onSubmit={handleSubmit(this.submit)}>
          <FormGroup>
            <Field
              validate={[required, email]}
              component={renderField}
              placeholder='Email'
              type='email'
              name='email'
            />
          </FormGroup>

          <FormGroup>
            <Field
              validate={[required]}
              component={renderField}
              placeholder='Password'
              type='password'
              name='password'
            />
          </FormGroup>

          {!this.state.submitting && (
            <Button
              type='submit'
              className='auth-form-submit-button'
              disabled={!valid}
              onClick={handleSubmit(this.submit)}
            >
              Sign In
            </Button>
          )}
        </Form>
      </>
    )
  }
}

const mapDispatchToProps = {
  reset: () => reset('loginForm'),
  createOrFetchUser
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'loginForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })(LoginForm)
)
