import {
  RECEIVED_AUTH_USER,
  CLEAR_AUTH_USER,
  receivedFirebaseUser
} from '../modules/auth/actions'
import { doneLoadingAuthUser } from '../modules/session/actions'
import { firestoreCollections } from '../utils/firebase'

export default (store) => (next) => (action) => {
  switch (action.type) {
    case RECEIVED_AUTH_USER:
      next(action)

      if (action.authUser) {
        startSyncingFirebaseUser(store, action.authUser.id)
      }

      break

    case CLEAR_AUTH_USER:
      next(action)
      stopSyncingFirebaseUser()
      break

    default:
      next(action)
  }
}

let unsubscribe

export function startSyncingFirebaseUser(store, userId) {
  unsubscribe = firestoreCollections.usersRef
    .doc(userId)
    .onSnapshot((userDoc) => {
      store.dispatch(receivedFirebaseUser(userDoc.data()))
      store.dispatch(doneLoadingAuthUser())
    })
}

export function stopSyncingFirebaseUser() {
  unsubscribe && unsubscribe()
}
