import { RECEIVED_USER_TASKS } from './actions'
import { CLEAR_AUTH_USER } from '../auth/actions'

export default function reducer(
  state = {
    data: new Map()
  },
  action
) {
  switch (action.type) {
    case RECEIVED_USER_TASKS:
      return {
        ...state,
        data: action.userTasks
      }
    case CLEAR_AUTH_USER:
      return {
        data: new Map()
      }
    default:
      return state
  }
}
