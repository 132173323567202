// eslint-disable-next-line no-undef
const url = new URL(window.location.href)
let isTracking = true

if (url.searchParams && url.searchParams.get('no_events')) {
  isTracking = false
}

export const trackPropertyEvent = (eventName, property) => {
  if (!isTracking) return

  const { address } = property

  track(eventName, {
    property_id: property.id,
    property_address_display_line1: address.displayLine1,
    property_city: address.city,
    property_state: address.state,
    property_zip: address.zip,
    property_county: property.county,
    property_address: address
  })
}

export const trackSchedulingEvent = (
  eventName,
  userId,
  propertyId,
  additionalEventData = {}
) => {
  if (!isTracking) return
  track(eventName, {
    user_id: userId,
    property_id: propertyId,
    ...additionalEventData
  })
}

export const trackAdLeadConversionEvent = (eventName, pageName) => {
  if (!isTracking) return
  const properties = {
    page_name: pageName,
    content_category: (
      pageName.charAt(0).toUpperCase() + pageName.slice(1)
    ).replace(/-/g, ' ')
  }

  trackAdEvent(eventName, properties)
}

export const trackAdEvent = (eventName, properties = {}) => {
  if (!isTracking) return
  // eslint-disable-next-line no-undef
  window.fbq('track', eventName, properties)

  // eslint-disable-next-line no-undef
  window.analytics.track(
    eventName,
    {
      ...properties
    },
    {
      integrations: {
        'Google Ads (Gtag)': true,
        Amplitude: false,
        'Customer.io': false
      }
    }
  )
}

export const track = (eventName, properties = {}) => {
  if (!isTracking) return
  // eslint-disable-next-line no-undef
  window.analytics.track(eventName, properties)
}
