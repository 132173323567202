import { SET_INITIAL_URL } from '../modules/url/actions'
import { setSignUpForm, RECEIVED_COOKIE_USER } from '../modules/session/actions'

const worker = (store: any) => (next: any) => (action: any) => {
  switch (action.type) {
    case SET_INITIAL_URL:
      bootstrapSession(store)
      next(action)
      break

    case RECEIVED_COOKIE_USER:
      next(action)
      break

    default:
      next(action)
  }
}

export default worker

export const bootstrapSession = async (store: any) => {
  const signUpForm = localStorage.getItem('signUpForm')

  if (signUpForm) {
    store.dispatch(setSignUpForm(JSON.parse(signUpForm)))
  }
}
