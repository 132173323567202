import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

import { firebaseConfig } from '../config'

firebase.initializeApp(firebaseConfig)

export const firestore = firebase.firestore()

export const firestoreCollections = {
  savedPropertiesRef: firestore.collection('saved_properties'),
  creditRequestsRef: firestore.collection('credit_requests'),
  propertySettingsRef: firestore.collection('property_settings'),
  usersRef: firestore.collection('users'),
  newPaymentSourcesRef: firestore.collection('new_payment_sources'),
  userTasksRef: firestore.collection('user_tasks'),
  neighborhoods: firestore.collection('neighborhoods'),
  userPropertyInteractionsRef: firestore.collection(
    'user_property_interactions'
  ),
  dataDumpRef: firestore.collection('data_dumps')
}

export default firebase
