import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button
} from 'reactstrap'
import styled from 'styled-components'
import connect from 'react-redux/es/connect/connect'
import { withRouter } from 'react-router-dom'
import { COMING_SOON_REGIONS } from 'constants/regions'
import { openAuthModal } from 'modules/auth/actions'
import { setNotificationIsVisible } from 'modules/page-layout/actions'
import firebase from 'utils/firebase'
import AuthModal from 'components/AuthModal/AuthModal'
import { COLORS, DownChevron } from 'components/Toolkit'

import 'styles/nav.css'

const LOGO_LINK =
  'https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/60d5e787a72594b02d6a6560_up-up-logo.svg'
const ABOUT_OPTIONS = [
  { url: 'https://upandup.co/about-us', label: 'About us' },
  { url: 'https://upandup.co/stories', label: 'Stories' },
  { url: 'https://upandup.co/faqs', label: 'FAQ' }
]

const StyledHeader = styled.header`
  padding: 20px 0;
  background-color: #fff;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: normal;
  box-sizing: border-box;
  overflow: visible;

  nav {
    box-shadow: 0 0px 0.1px 0px;
  }

  @media (max-width: 991px) {
    position: relative;
    z-index: 4;
  }
`

const StyledHeaderContainer = styled.div`
  padding: 0 15px;
  position: relative;
  z-index: 1;
  display: block;
  max-width: 1440px;
  margin: 0 auto;
`

const StyledHomeLink = styled.a`
  margin-left: 40px;
  text-align: left;
  outline: none;
  max-width: 100%;
  display: inline-block;
  line-height: 14px;

  @media (max-width: 991px) {
    margin-left: 30px;
  }
`

const StyledLogo = styled.img`
  width: 90px;
  height: 32px;
`

const StyledHeaderMenu = styled.nav`
  display: flex;
  padding-left: 0px;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 991px) {
    display: flex;
    width: 100%;
    margin-right: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
`

const StyledHeaderButtons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
`

const StyledHeaderMenuLink = styled.a`
  &&& {
    margin-right: 0;
    margin-left: 0;
    padding: 10px 12px;
    font-family: Ftn-Book-45, sans-serif;
    color: #403e3d;
    font-size: 18px;
    font-weight: 400;
    text-align: left;

    @media (max-width: 991px) {
      font-family: Ftn-Medium-55, sans-serif;
      color: #403e3d;
      font-size: 20px;
      display: block;
      padding: 20px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
`

const StyledMobileMenu = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: flex;
    margin-right: 15px;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
  }
`

const StyledDesktopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  transition: all 0.5s ease;

  @media (max-width: 991px) {
    height: calc(100vh - 91px);
    justify-content: start;
    width: 597px;
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: flex;
    margin-top: 50px;
    align-items: center;
    background-color: #fff;
    text-align: center;
    flex-direction: column;

    transform: translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 0;

    transform: ${(props) =>
      props.isHamburgerOpen
        ? 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);'
        : ''};
    opacity: ${(props) => (props.isHamburgerOpen ? '1' : '')};
  }

  @media (max-width: 597px) {
    width: 100%;
  }
`

const StyledHambuger = styled.div`
  display: inline-block;
  padding: 10px;
  cursor: pointer;
`

const StyledEmailSent = styled.span`
  margin-left: 0.5rem;
  font-weight: 600;
`

const VerifyNavbar = styled.div`
  width: 100%;
  background-color: ${COLORS.PRIMARY.DARKEST_GRAY};
  color: #fff;
  border: none;
  text-align: center;
  padding: 3px 0;
  margin-bottom: 5px;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 32px;

  @media (max-width: 991px) {
    margin-top: 0;
  }
`

const StyledResend = styled.span`
  text-decoration: underline;
  margin-left: 0.5rem;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`

const StyledDropdown = styled(UncontrolledDropdown)`
  && {
    display: block;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }
`

const StyledDropdownToggle = styled(DropdownToggle)`
  && {
    border: none;
    display: block;
    padding: 10px 12px;
    font-family: Ftn-Book-45, sans-serif;
    color: #403e3d;
    font-weight: 400;
    font-size: 18px;
  }
`

const StyledDropdownMenu = styled(DropdownMenu)`
  && {
    border: none;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 10%);

    a {
      display: block;
      padding: 10px 32px;
      font-family: Ftn-Book-45, sans-serif;
      color: ${COLORS.NEUTRALS.DARKER_GRAY};
    }
  }
`

const StyledCollapseButton = styled(Button)`
  && {
    border: none;
    font-weight: 400;
    padding: 20px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-family: Ftn-Medium-55, sans-serif;
    color: #403e3d;
    font-size: 18px;
    text-align: left;
  }
`

const StyledCollapse = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 16px;
  padding-bottom: 19px;
  padding-left: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: transparent;
`

const StyledCollapseLink = styled.a`
  font-family: Ftn-Medium-55, sans-serif;
  color: #6e7272;
  font-size: 16px;
  display: block;
  padding: 10px 24px;
  text-align: left;
`

const StyledNavLinksWrapped = styled(Col)`
  &&& {
    @media (max-width: 991px) {
      position: initial;
    }
  }
`

const StyledHeaderRow = styled(Row)`
  &&& {
    align-items: center;
    margin: 0 -40px !important;
    flex-wrap: nowrap;
  }
`

const StyledHomeLinkWrapped = styled(Col)`
  line-height: 14px;
`

const StyledHamburgerLinks = styled.div`
  display: none;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }
`

class NavBar extends Component {
  state = {
    isHamburgerOpen: false,
    emailSent: false,
    collapseOpen: false
  }

  static propTypes = {
    authUser: PropTypes.object,
    openAuthModal: PropTypes.func.isRequired
  }

  sendVerification = () => {
    const user = firebase.auth().currentUser

    if (user) {
      user.sendEmailVerification()
      this.setState({ emailSent: true })

      setTimeout(() => {
        this.setState({ emailSent: false })
      }, 5000)
    }
  }

  render() {
    const { isHamburgerOpen } = this.state
    const { authUser, openAuthModal, location, setNotificationIsVisible } =
      this.props

    const regionCode = COMING_SOON_REGIONS.reduce(
      (isInUrl, regionCode) =>
        isInUrl ||
        location.pathname.toLowerCase().indexOf(`homes/${regionCode}`) !== -1
          ? regionCode
          : false,
      false
    )

    const showEmailVerificationBanner =
      authUser &&
      firebase.auth().currentUser &&
      !firebase.auth().currentUser.emailVerified

    const showComingSoonRegionInfoBanner =
      !showEmailVerificationBanner && regionCode

    setNotificationIsVisible(
      Boolean(showEmailVerificationBanner || showComingSoonRegionInfoBanner)
    )

    return (
      <>
        <StyledHeader>
          <StyledHeaderContainer>
            <StyledHeaderRow>
              <StyledHomeLinkWrapped sm={6} md={2}>
                <StyledHomeLink href='https://upandup.co/'>
                  <StyledLogo src={LOGO_LINK} />
                </StyledHomeLink>
              </StyledHomeLinkWrapped>

              <StyledNavLinksWrapped sm={6} md={10}>
                <StyledMobileMenu>
                  <StyledHambuger
                    onClick={() =>
                      this.setState({ isHamburgerOpen: !isHamburgerOpen })
                    }
                  >
                    <img
                      style={{ filter: 'invert(100%)' }}
                      src='https://assets.website-files.com/60d5c9ad261bab0ce1457c1a/616f154b37dd2edc8ae29b2c_menu-white.png'
                      alt='menu'
                    />
                  </StyledHambuger>
                </StyledMobileMenu>
                <StyledDesktopMenu isHamburgerOpen={isHamburgerOpen}>
                  <StyledHeaderMenu>
                    <StyledDropdown>
                      <StyledDropdownToggle>
                        <span style={{ marginRight: '0.5rem' }}>About</span>
                        <DownChevron
                          color={COLORS.NEUTRALS.DARKER_GRAY}
                          height={9}
                          width={9}
                        />
                      </StyledDropdownToggle>
                      <StyledDropdownMenu>
                        {ABOUT_OPTIONS.map((item) => (
                          <li key={item.label}>
                            <a href={item.url}>{item.label}</a>
                          </li>
                        ))}
                      </StyledDropdownMenu>
                    </StyledDropdown>
                    <StyledHamburgerLinks>
                      <StyledCollapseButton
                        caret='true'
                        onClick={() =>
                          this.setState({
                            collapseOpen: !this.state.collapseOpen
                          })
                        }
                      >
                        <span style={{ marginRight: '0.5rem' }}>About</span>
                        <DownChevron
                          color={COLORS.NEUTRALS.DARKER_GRAY}
                          height={9}
                          width={9}
                        />
                      </StyledCollapseButton>
                      {this.state.collapseOpen && (
                        <StyledCollapse>
                          {ABOUT_OPTIONS.map((item) => (
                            <StyledCollapseLink
                              key={item.label}
                              href={item.url}
                            >
                              {item.label}
                            </StyledCollapseLink>
                          ))}
                        </StyledCollapse>
                      )}
                    </StyledHamburgerLinks>
                    <StyledHeaderMenuLink href='https://upandup.co/how-it-works'>
                      How it works
                    </StyledHeaderMenuLink>
                    <StyledHeaderMenuLink href='https://upandup.co/careers'>
                      Careers
                    </StyledHeaderMenuLink>
                    <StyledHeaderMenuLink href='/homes/all'>
                      Find a home
                    </StyledHeaderMenuLink>
                    {!authUser ? (
                      <StyledHeaderMenuLink onClick={() => openAuthModal()}>
                        Account
                      </StyledHeaderMenuLink>
                    ) : (
                      <StyledHeaderMenuLink
                        href='/account'
                        onClick={this.close}
                      >
                        Account
                      </StyledHeaderMenuLink>
                    )}
                  </StyledHeaderMenu>
                  <StyledHeaderButtons></StyledHeaderButtons>
                </StyledDesktopMenu>
              </StyledNavLinksWrapped>
            </StyledHeaderRow>
          </StyledHeaderContainer>
        </StyledHeader>
        {showEmailVerificationBanner ? (
          <VerifyNavbar>
            Please verify your email.{' '}
            {this.state.emailSent && (
              <StyledEmailSent>Email Sent!</StyledEmailSent>
            )}{' '}
            <StyledResend onClick={this.sendVerification}>
              Resend verification
            </StyledResend>
          </VerifyNavbar>
        ) : null}

        <AuthModal />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.authUser
})

const mapDispatchToProps = {
  openAuthModal,
  setNotificationIsVisible
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar))
