import { SET_TOAST, CLEAR_TOAST } from './actions'
import { uuid } from 'short-uuid'

export default function reducer(state = null, action) {
  switch (action.type) {
    case SET_TOAST:
      return {
        id: uuid.v4(),
        ...action.payload
      }
    case CLEAR_TOAST:
      return null
    default:
      return state
  }
}
