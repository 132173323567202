import React, { memo } from 'react'
import styled from 'styled-components'

const StyledLabel = styled.label`
  color: #55545a;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0em;
  padding: 9px 0;
`

function LabelText(props) {
  return (
    <div>
      <StyledLabel {...props} />
    </div>
  )
}

export default memo(LabelText)
