import React from 'react'
import styled from 'styled-components'

import { BodyText } from 'components/Toolkit'

const COLOR = 'rgba(85, 84, 90, 0.5)'
const COLOR_LIGHT = 'rgba(85, 84, 90, 0.25)'

const StyledDivider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${COLOR_LIGHT};
  }
`

const StyledBodyText = styled(BodyText)`
  color: ${COLOR};
  margin: 0 1rem;
`

export default function HorizontalOrDivider() {
  return (
    <StyledDivider>
      <StyledBodyText>OR</StyledBodyText>
    </StyledDivider>
  )
}
