export const REGION_CODES = {
  'dallas-tx': 'Dallas',
  'atlanta-ga': 'Atlanta',
  'charlotte-nc': 'Charlotte',
  'los-angeles-ca': 'Los Angeles',
  'phoenix-az': 'Phoenix',
  'raleigh-nc': 'Raleigh',
  'st-louis-mo': 'St. Louis',
  'tampa-FL': 'Tampa'
}

export const SCHEDULABLE_REGIONS = {
  'st-louis-mo': 'St. Louis'
}

export const COMING_SOON_REGIONS = [
  'dallas-tx',
  'atlanta-ga',
  'charlotte-nc',
  'los-angeles-ca',
  'phoenix-az',
  'raleigh-nc',
  'tampa-FL'
]

export const REGION_PHONE_NUMBERS = {
  'phoenix-az': '+16026988945',
  'raleigh-nc': '+19197508587'
}

export const DEFAULT_PHONE_NUMBER = '+19292989920'
