export const SET_TOAST = 'TOAST/SET_TOAST'
export const CLEAR_TOAST = 'CLEAR_TOAST/CLEAR_TOAST'

export const clearToast = () => ({
  type: CLEAR_TOAST
})

// icon: just an icon like this 🎉
// text: whatever you want the toast text to say
// action {text, callback}: what you want the action text to be and what you want to happen when you click it
// duration: how long you want the toast to show up for
export const setToast = ({ icon, text, action, duration }) => ({
  type: SET_TOAST,
  payload: {
    text,
    icon: icon || null,
    action: action || null, // {text, callback}
    duration: duration || 3000
  }
})
