import React from 'react'

import { tlds } from '../data/tlds'

export const isValidEmail = (value) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

export const required = (value) =>
  value &&
  ((typeof value === 'string' && value.trim()) || typeof value !== 'string')
    ? undefined
    : 'Required'

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

export const registerEmail = (value) => {
  let valid = value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)

  if (valid) {
    const tld = value.slice(value.lastIndexOf('.') + 1)

    valid = valid && tlds[tld]
  }

  return !valid ? 'Invalid email address' : undefined
}

export const isValidPhoneNumber = (value) => {
  const nums = onlyNums(value)

  return nums && (/^[0-9]{10}$/g.test(nums) || /^1[0-9]{10}$/g.test(nums))
}

export const phoneNumber = (value) => {
  const nums = onlyNums(value)

  return nums && !/^1[0-9]{10}$/g.test(nums)
    ? 'Invalid phone number, must be 11 digits'
    : undefined
}

export const isValidSsn = (value) => {
  const nums = onlyNums(value)

  return nums && /^[0-9]{9}$/g.test(nums)
}

export const normalizeMoney = (amount) => {
  if (!amount) return ''

  const numbers = amount.replace(/\D/g, '')
  return numbers.length ? `$${Number(numbers).toLocaleString()}` : '$'
}

export const normalizePhone = (value, previousValue) => {
  if (!value) {
    return value
  }

  let nums = onlyNums(value)

  // Never let the first number be a 1
  if (nums && nums.length && nums[0] === '1') {
    nums = nums.substring(1)
  }

  if (nums.length > 0) {
    if (!previousValue || value.length > previousValue.length) {
      // typing forward
      if (nums.length === 3) {
        return `+1 (${nums})`
      }
      if (nums.length === 6) {
        return `+1 (${nums.slice(0, 3)}) ${nums.slice(3)}-`
      }
    }
    if (nums.length <= 3) {
      return `+1 ${nums}`
    }
    if (nums.length <= 6) {
      return `+1 (${nums.slice(0, 3)}) ${nums.slice(3)}`
    }
    return `+1 (${nums.slice(0, 3)}) ${nums.slice(3, 6)}-${nums.slice(6, 10)}`
  } else {
    return '+1 '
  }
}

export const normalizeSsn = (value, previousValue) => {
  if (!value) {
    return value
  }
  const nums = onlyNums(value)

  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (nums.length === 3) {
      return `${nums} - `
    }
    if (nums.length === 5) {
      return `${nums.slice(0, 3)} - ${nums.slice(3)} - `
    }
  }
  if (nums.length <= 3) {
    return nums
  }
  if (nums.length <= 5) {
    return `${nums.slice(0, 3)} - ${nums.slice(3)}`
  }
  return `${nums.slice(0, 3)} - ${nums.slice(3, 5)} - ${nums.slice(5, 9)}`
}

export const renderField = ({
  input,
  placeholder,
  type,
  meta: { touched, error }
}) => (
  <input
    {...input}
    placeholder={placeholder}
    type={type}
    className={touched && error ? 'auth-input error' : 'auth-input'}
  />
)

export const onlyNums = (value) => (value ? value.replace(/\D/g, '') : value)
