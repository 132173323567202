import React, { memo, useEffect } from 'react'
import styled from 'styled-components'
import { BodyText } from '..'

const StyledToast = styled.div`
  max-width: 400px;
  width: 100%;
  position: fixed;
  bottom: 20px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99999999999;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.05);
  padding: 20px;

  @media (max-width: 600px) {
    bottom: 0;
    right: 0;
    max-width: 600px;
  }
`

const StyledIcon = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  margin-right: 10px;
`

const StyledAction = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
`

function Toast({ id, text, icon, action, duration, clear }) {
  useEffect(() => {
    const remove = setTimeout(clear, duration)

    return () => {
      clearTimeout(remove)
    }
  }, [id, duration, clear])

  return (
    <StyledToast>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <BodyText>{text}</BodyText>
      {action && (
        <StyledAction
          onClick={() => {
            clear()
            action.callback()
          }}
        >
          {action.text}
        </StyledAction>
      )}
    </StyledToast>
  )
}

export default memo(Toast)
