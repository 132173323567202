export const tlds = {
  aaa: 'aaa',
  aarp: 'aarp',
  abarth: 'abarth',
  abb: 'abb',
  abbott: 'abbott',
  abbvie: 'abbvie',
  abc: 'abc',
  able: 'able',
  abogado: 'abogado',
  abudhabi: 'abudhabi',
  ac: 'ac',
  academy: 'academy',
  accenture: 'accenture',
  accountant: 'accountant',
  accountants: 'accountants',
  aco: 'aco',
  actor: 'actor',
  ad: 'ad',
  adac: 'adac',
  ads: 'ads',
  adult: 'adult',
  ae: 'ae',
  aeg: 'aeg',
  aero: 'aero',
  aetna: 'aetna',
  af: 'af',
  afamilycompany: 'afamilycompany',
  afl: 'afl',
  africa: 'africa',
  ag: 'ag',
  agakhan: 'agakhan',
  agency: 'agency',
  ai: 'ai',
  aig: 'aig',
  aigo: 'aigo',
  airbus: 'airbus',
  airforce: 'airforce',
  airtel: 'airtel',
  akdn: 'akdn',
  al: 'al',
  alfaromeo: 'alfaromeo',
  alibaba: 'alibaba',
  alipay: 'alipay',
  allfinanz: 'allfinanz',
  allstate: 'allstate',
  ally: 'ally',
  alsace: 'alsace',
  alstom: 'alstom',
  am: 'am',
  americanexpress: 'americanexpress',
  americanfamily: 'americanfamily',
  amex: 'amex',
  amfam: 'amfam',
  amica: 'amica',
  amsterdam: 'amsterdam',
  analytics: 'analytics',
  android: 'android',
  anquan: 'anquan',
  anz: 'anz',
  ao: 'ao',
  aol: 'aol',
  apartments: 'apartments',
  app: 'app',
  apple: 'apple',
  aq: 'aq',
  aquarelle: 'aquarelle',
  ar: 'ar',
  arab: 'arab',
  aramco: 'aramco',
  archi: 'archi',
  army: 'army',
  arpa: 'arpa',
  art: 'art',
  arte: 'arte',
  as: 'as',
  asda: 'asda',
  asia: 'asia',
  associates: 'associates',
  at: 'at',
  athleta: 'athleta',
  attorney: 'attorney',
  au: 'au',
  auction: 'auction',
  audi: 'audi',
  audible: 'audible',
  audio: 'audio',
  auspost: 'auspost',
  author: 'author',
  auto: 'auto',
  autos: 'autos',
  avianca: 'avianca',
  aw: 'aw',
  aws: 'aws',
  ax: 'ax',
  axa: 'axa',
  az: 'az',
  azure: 'azure',
  ba: 'ba',
  baby: 'baby',
  baidu: 'baidu',
  banamex: 'banamex',
  bananarepublic: 'bananarepublic',
  band: 'band',
  bank: 'bank',
  bar: 'bar',
  barcelona: 'barcelona',
  barclaycard: 'barclaycard',
  barclays: 'barclays',
  barefoot: 'barefoot',
  bargains: 'bargains',
  baseball: 'baseball',
  basketball: 'basketball',
  bauhaus: 'bauhaus',
  bayern: 'bayern',
  bb: 'bb',
  bbc: 'bbc',
  bbt: 'bbt',
  bbva: 'bbva',
  bcg: 'bcg',
  bcn: 'bcn',
  bd: 'bd',
  be: 'be',
  beats: 'beats',
  beauty: 'beauty',
  beer: 'beer',
  bentley: 'bentley',
  berlin: 'berlin',
  best: 'best',
  bestbuy: 'bestbuy',
  bet: 'bet',
  bf: 'bf',
  bg: 'bg',
  bh: 'bh',
  bharti: 'bharti',
  bi: 'bi',
  bible: 'bible',
  bid: 'bid',
  bike: 'bike',
  bing: 'bing',
  bingo: 'bingo',
  bio: 'bio',
  biz: 'biz',
  bj: 'bj',
  black: 'black',
  blackfriday: 'blackfriday',
  blockbuster: 'blockbuster',
  blog: 'blog',
  bloomberg: 'bloomberg',
  blue: 'blue',
  bm: 'bm',
  bms: 'bms',
  bmw: 'bmw',
  bn: 'bn',
  bnl: 'bnl',
  bnpparibas: 'bnpparibas',
  bo: 'bo',
  boats: 'boats',
  boehringer: 'boehringer',
  bofa: 'bofa',
  bom: 'bom',
  bond: 'bond',
  boo: 'boo',
  book: 'book',
  booking: 'booking',
  bosch: 'bosch',
  bostik: 'bostik',
  boston: 'boston',
  bot: 'bot',
  boutique: 'boutique',
  box: 'box',
  br: 'br',
  bradesco: 'bradesco',
  bridgestone: 'bridgestone',
  broadway: 'broadway',
  broker: 'broker',
  brother: 'brother',
  brussels: 'brussels',
  bs: 'bs',
  bt: 'bt',
  budapest: 'budapest',
  bugatti: 'bugatti',
  build: 'build',
  builders: 'builders',
  business: 'business',
  buy: 'buy',
  buzz: 'buzz',
  bv: 'bv',
  bw: 'bw',
  by: 'by',
  bz: 'bz',
  bzh: 'bzh',
  ca: 'ca',
  cab: 'cab',
  cafe: 'cafe',
  cal: 'cal',
  call: 'call',
  calvinklein: 'calvinklein',
  cam: 'cam',
  camera: 'camera',
  camp: 'camp',
  cancerresearch: 'cancerresearch',
  canon: 'canon',
  capetown: 'capetown',
  capital: 'capital',
  capitalone: 'capitalone',
  car: 'car',
  caravan: 'caravan',
  cards: 'cards',
  care: 'care',
  career: 'career',
  careers: 'careers',
  cars: 'cars',
  cartier: 'cartier',
  casa: 'casa',
  case: 'case',
  caseih: 'caseih',
  cash: 'cash',
  casino: 'casino',
  cat: 'cat',
  catering: 'catering',
  catholic: 'catholic',
  cba: 'cba',
  cbn: 'cbn',
  cbre: 'cbre',
  cbs: 'cbs',
  cc: 'cc',
  cd: 'cd',
  ceb: 'ceb',
  center: 'center',
  ceo: 'ceo',
  cern: 'cern',
  cf: 'cf',
  cfa: 'cfa',
  cfd: 'cfd',
  cg: 'cg',
  ch: 'ch',
  chanel: 'chanel',
  channel: 'channel',
  charity: 'charity',
  chase: 'chase',
  chat: 'chat',
  cheap: 'cheap',
  chintai: 'chintai',
  christmas: 'christmas',
  chrome: 'chrome',
  chrysler: 'chrysler',
  church: 'church',
  ci: 'ci',
  cipriani: 'cipriani',
  circle: 'circle',
  cisco: 'cisco',
  citadel: 'citadel',
  citi: 'citi',
  citic: 'citic',
  city: 'city',
  cityeats: 'cityeats',
  ck: 'ck',
  cl: 'cl',
  claims: 'claims',
  cleaning: 'cleaning',
  click: 'click',
  clinic: 'clinic',
  clinique: 'clinique',
  clothing: 'clothing',
  cloud: 'cloud',
  club: 'club',
  clubmed: 'clubmed',
  cm: 'cm',
  cn: 'cn',
  co: 'co',
  coach: 'coach',
  codes: 'codes',
  coffee: 'coffee',
  college: 'college',
  cologne: 'cologne',
  com: 'com',
  comcast: 'comcast',
  commbank: 'commbank',
  community: 'community',
  company: 'company',
  compare: 'compare',
  computer: 'computer',
  comsec: 'comsec',
  condos: 'condos',
  construction: 'construction',
  consulting: 'consulting',
  contact: 'contact',
  contractors: 'contractors',
  cooking: 'cooking',
  cookingchannel: 'cookingchannel',
  cool: 'cool',
  coop: 'coop',
  corsica: 'corsica',
  country: 'country',
  coupon: 'coupon',
  coupons: 'coupons',
  courses: 'courses',
  cr: 'cr',
  credit: 'credit',
  creditcard: 'creditcard',
  creditunion: 'creditunion',
  cricket: 'cricket',
  crown: 'crown',
  crs: 'crs',
  cruise: 'cruise',
  cruises: 'cruises',
  csc: 'csc',
  cu: 'cu',
  cuisinella: 'cuisinella',
  cv: 'cv',
  cw: 'cw',
  cx: 'cx',
  cy: 'cy',
  cymru: 'cymru',
  cyou: 'cyou',
  cz: 'cz',
  dabur: 'dabur',
  dad: 'dad',
  dance: 'dance',
  data: 'data',
  date: 'date',
  dating: 'dating',
  datsun: 'datsun',
  day: 'day',
  dclk: 'dclk',
  dds: 'dds',
  de: 'de',
  deal: 'deal',
  dealer: 'dealer',
  deals: 'deals',
  degree: 'degree',
  delivery: 'delivery',
  dell: 'dell',
  deloitte: 'deloitte',
  delta: 'delta',
  democrat: 'democrat',
  dental: 'dental',
  dentist: 'dentist',
  desi: 'desi',
  design: 'design',
  dev: 'dev',
  dhl: 'dhl',
  diamonds: 'diamonds',
  diet: 'diet',
  digital: 'digital',
  direct: 'direct',
  directory: 'directory',
  discount: 'discount',
  discover: 'discover',
  dish: 'dish',
  diy: 'diy',
  dj: 'dj',
  dk: 'dk',
  dm: 'dm',
  dnp: 'dnp',
  do: 'do',
  docs: 'docs',
  doctor: 'doctor',
  dodge: 'dodge',
  dog: 'dog',
  domains: 'domains',
  dot: 'dot',
  download: 'download',
  drive: 'drive',
  dtv: 'dtv',
  dubai: 'dubai',
  duck: 'duck',
  dunlop: 'dunlop',
  duns: 'duns',
  dupont: 'dupont',
  durban: 'durban',
  dvag: 'dvag',
  dvr: 'dvr',
  dz: 'dz',
  earth: 'earth',
  eat: 'eat',
  ec: 'ec',
  eco: 'eco',
  edeka: 'edeka',
  edu: 'edu',
  education: 'education',
  ee: 'ee',
  eg: 'eg',
  email: 'email',
  emerck: 'emerck',
  energy: 'energy',
  engineer: 'engineer',
  engineering: 'engineering',
  enterprises: 'enterprises',
  epson: 'epson',
  equipment: 'equipment',
  er: 'er',
  ericsson: 'ericsson',
  erni: 'erni',
  es: 'es',
  esq: 'esq',
  estate: 'estate',
  esurance: 'esurance',
  et: 'et',
  etisalat: 'etisalat',
  eu: 'eu',
  eurovision: 'eurovision',
  eus: 'eus',
  events: 'events',
  everbank: 'everbank',
  exchange: 'exchange',
  expert: 'expert',
  exposed: 'exposed',
  express: 'express',
  extraspace: 'extraspace',
  fage: 'fage',
  fail: 'fail',
  fairwinds: 'fairwinds',
  faith: 'faith',
  family: 'family',
  fan: 'fan',
  fans: 'fans',
  farm: 'farm',
  farmers: 'farmers',
  fashion: 'fashion',
  fast: 'fast',
  fedex: 'fedex',
  feedback: 'feedback',
  ferrari: 'ferrari',
  ferrero: 'ferrero',
  fi: 'fi',
  fiat: 'fiat',
  fidelity: 'fidelity',
  fido: 'fido',
  film: 'film',
  final: 'final',
  finance: 'finance',
  financial: 'financial',
  fire: 'fire',
  firestone: 'firestone',
  firmdale: 'firmdale',
  fish: 'fish',
  fishing: 'fishing',
  fit: 'fit',
  fitness: 'fitness',
  fj: 'fj',
  fk: 'fk',
  flickr: 'flickr',
  flights: 'flights',
  flir: 'flir',
  florist: 'florist',
  flowers: 'flowers',
  fly: 'fly',
  fm: 'fm',
  fo: 'fo',
  foo: 'foo',
  food: 'food',
  foodnetwork: 'foodnetwork',
  football: 'football',
  ford: 'ford',
  forex: 'forex',
  forsale: 'forsale',
  forum: 'forum',
  foundation: 'foundation',
  fox: 'fox',
  fr: 'fr',
  free: 'free',
  fresenius: 'fresenius',
  frl: 'frl',
  frogans: 'frogans',
  frontdoor: 'frontdoor',
  frontier: 'frontier',
  ftr: 'ftr',
  fujitsu: 'fujitsu',
  fujixerox: 'fujixerox',
  fun: 'fun',
  fund: 'fund',
  furniture: 'furniture',
  futbol: 'futbol',
  fyi: 'fyi',
  ga: 'ga',
  gal: 'gal',
  gallery: 'gallery',
  gallo: 'gallo',
  gallup: 'gallup',
  game: 'game',
  games: 'games',
  gap: 'gap',
  garden: 'garden',
  gb: 'gb',
  gbiz: 'gbiz',
  gd: 'gd',
  gdn: 'gdn',
  ge: 'ge',
  gea: 'gea',
  gent: 'gent',
  genting: 'genting',
  george: 'george',
  gf: 'gf',
  gg: 'gg',
  ggee: 'ggee',
  gh: 'gh',
  gi: 'gi',
  gift: 'gift',
  gifts: 'gifts',
  gives: 'gives',
  giving: 'giving',
  gl: 'gl',
  glade: 'glade',
  glass: 'glass',
  gle: 'gle',
  global: 'global',
  globo: 'globo',
  gm: 'gm',
  gmail: 'gmail',
  gmbh: 'gmbh',
  gmo: 'gmo',
  gmx: 'gmx',
  gn: 'gn',
  godaddy: 'godaddy',
  gold: 'gold',
  goldpoint: 'goldpoint',
  golf: 'golf',
  goo: 'goo',
  goodyear: 'goodyear',
  goog: 'goog',
  google: 'google',
  gop: 'gop',
  got: 'got',
  gov: 'gov',
  gp: 'gp',
  gq: 'gq',
  gr: 'gr',
  grainger: 'grainger',
  graphics: 'graphics',
  gratis: 'gratis',
  green: 'green',
  gripe: 'gripe',
  grocery: 'grocery',
  group: 'group',
  gs: 'gs',
  gt: 'gt',
  gu: 'gu',
  guardian: 'guardian',
  gucci: 'gucci',
  guge: 'guge',
  guide: 'guide',
  guitars: 'guitars',
  guru: 'guru',
  gw: 'gw',
  gy: 'gy',
  hair: 'hair',
  hamburg: 'hamburg',
  hangout: 'hangout',
  haus: 'haus',
  hbo: 'hbo',
  hdfc: 'hdfc',
  hdfcbank: 'hdfcbank',
  health: 'health',
  healthcare: 'healthcare',
  help: 'help',
  helsinki: 'helsinki',
  here: 'here',
  hermes: 'hermes',
  hgtv: 'hgtv',
  hiphop: 'hiphop',
  hisamitsu: 'hisamitsu',
  hitachi: 'hitachi',
  hiv: 'hiv',
  hk: 'hk',
  hkt: 'hkt',
  hm: 'hm',
  hn: 'hn',
  hockey: 'hockey',
  holdings: 'holdings',
  holiday: 'holiday',
  homedepot: 'homedepot',
  homegoods: 'homegoods',
  homes: 'homes',
  homesense: 'homesense',
  honda: 'honda',
  horse: 'horse',
  hospital: 'hospital',
  host: 'host',
  hosting: 'hosting',
  hot: 'hot',
  hoteles: 'hoteles',
  hotels: 'hotels',
  hotmail: 'hotmail',
  house: 'house',
  how: 'how',
  hr: 'hr',
  hsbc: 'hsbc',
  ht: 'ht',
  hu: 'hu',
  hughes: 'hughes',
  hyatt: 'hyatt',
  hyundai: 'hyundai',
  ibm: 'ibm',
  icbc: 'icbc',
  ice: 'ice',
  icu: 'icu',
  id: 'id',
  ie: 'ie',
  ieee: 'ieee',
  ifm: 'ifm',
  ikano: 'ikano',
  il: 'il',
  im: 'im',
  imamat: 'imamat',
  imdb: 'imdb',
  immo: 'immo',
  immobilien: 'immobilien',
  in: 'in',
  inc: 'inc',
  industries: 'industries',
  infiniti: 'infiniti',
  info: 'info',
  ing: 'ing',
  ink: 'ink',
  institute: 'institute',
  insurance: 'insurance',
  insure: 'insure',
  int: 'int',
  intel: 'intel',
  international: 'international',
  intuit: 'intuit',
  investments: 'investments',
  io: 'io',
  ipiranga: 'ipiranga',
  iq: 'iq',
  ir: 'ir',
  irish: 'irish',
  is: 'is',
  iselect: 'iselect',
  ismaili: 'ismaili',
  ist: 'ist',
  istanbul: 'istanbul',
  it: 'it',
  itau: 'itau',
  itv: 'itv',
  iveco: 'iveco',
  jaguar: 'jaguar',
  java: 'java',
  jcb: 'jcb',
  jcp: 'jcp',
  je: 'je',
  jeep: 'jeep',
  jetzt: 'jetzt',
  jewelry: 'jewelry',
  jio: 'jio',
  jll: 'jll',
  jm: 'jm',
  jmp: 'jmp',
  jnj: 'jnj',
  jo: 'jo',
  jobs: 'jobs',
  joburg: 'joburg',
  jot: 'jot',
  joy: 'joy',
  jp: 'jp',
  jpmorgan: 'jpmorgan',
  jprs: 'jprs',
  juegos: 'juegos',
  juniper: 'juniper',
  kaufen: 'kaufen',
  kddi: 'kddi',
  ke: 'ke',
  kerryhotels: 'kerryhotels',
  kerrylogistics: 'kerrylogistics',
  kerryproperties: 'kerryproperties',
  kfh: 'kfh',
  kg: 'kg',
  kh: 'kh',
  ki: 'ki',
  kia: 'kia',
  kim: 'kim',
  kinder: 'kinder',
  kindle: 'kindle',
  kitchen: 'kitchen',
  kiwi: 'kiwi',
  km: 'km',
  kn: 'kn',
  koeln: 'koeln',
  komatsu: 'komatsu',
  kosher: 'kosher',
  kp: 'kp',
  kpmg: 'kpmg',
  kpn: 'kpn',
  kr: 'kr',
  krd: 'krd',
  kred: 'kred',
  kuokgroup: 'kuokgroup',
  kw: 'kw',
  ky: 'ky',
  kyoto: 'kyoto',
  kz: 'kz',
  la: 'la',
  lacaixa: 'lacaixa',
  ladbrokes: 'ladbrokes',
  lamborghini: 'lamborghini',
  lamer: 'lamer',
  lancaster: 'lancaster',
  lancia: 'lancia',
  lancome: 'lancome',
  land: 'land',
  landrover: 'landrover',
  lanxess: 'lanxess',
  lasalle: 'lasalle',
  lat: 'lat',
  latino: 'latino',
  latrobe: 'latrobe',
  law: 'law',
  lawyer: 'lawyer',
  lb: 'lb',
  lc: 'lc',
  lds: 'lds',
  lease: 'lease',
  leclerc: 'leclerc',
  lefrak: 'lefrak',
  legal: 'legal',
  lego: 'lego',
  lexus: 'lexus',
  lgbt: 'lgbt',
  li: 'li',
  liaison: 'liaison',
  lidl: 'lidl',
  life: 'life',
  lifeinsurance: 'lifeinsurance',
  lifestyle: 'lifestyle',
  lighting: 'lighting',
  like: 'like',
  lilly: 'lilly',
  limited: 'limited',
  limo: 'limo',
  lincoln: 'lincoln',
  linde: 'linde',
  link: 'link',
  lipsy: 'lipsy',
  live: 'live',
  living: 'living',
  lixil: 'lixil',
  lk: 'lk',
  llc: 'llc',
  loan: 'loan',
  loans: 'loans',
  locker: 'locker',
  locus: 'locus',
  loft: 'loft',
  lol: 'lol',
  london: 'london',
  lotte: 'lotte',
  lotto: 'lotto',
  love: 'love',
  lpl: 'lpl',
  lplfinancial: 'lplfinancial',
  lr: 'lr',
  ls: 'ls',
  lt: 'lt',
  ltd: 'ltd',
  ltda: 'ltda',
  lu: 'lu',
  lundbeck: 'lundbeck',
  lupin: 'lupin',
  luxe: 'luxe',
  luxury: 'luxury',
  lv: 'lv',
  ly: 'ly',
  ma: 'ma',
  macys: 'macys',
  madrid: 'madrid',
  maif: 'maif',
  maison: 'maison',
  makeup: 'makeup',
  man: 'man',
  management: 'management',
  mango: 'mango',
  map: 'map',
  market: 'market',
  marketing: 'marketing',
  markets: 'markets',
  marriott: 'marriott',
  marshalls: 'marshalls',
  maserati: 'maserati',
  mattel: 'mattel',
  mba: 'mba',
  mc: 'mc',
  mckinsey: 'mckinsey',
  md: 'md',
  me: 'me',
  med: 'med',
  media: 'media',
  meet: 'meet',
  melbourne: 'melbourne',
  meme: 'meme',
  memorial: 'memorial',
  men: 'men',
  menu: 'menu',
  merckmsd: 'merckmsd',
  metlife: 'metlife',
  mg: 'mg',
  mh: 'mh',
  miami: 'miami',
  microsoft: 'microsoft',
  mil: 'mil',
  mini: 'mini',
  mint: 'mint',
  mit: 'mit',
  mitsubishi: 'mitsubishi',
  mk: 'mk',
  ml: 'ml',
  mlb: 'mlb',
  mls: 'mls',
  mm: 'mm',
  mma: 'mma',
  mn: 'mn',
  mo: 'mo',
  mobi: 'mobi',
  mobile: 'mobile',
  mobily: 'mobily',
  moda: 'moda',
  moe: 'moe',
  moi: 'moi',
  mom: 'mom',
  monash: 'monash',
  money: 'money',
  monster: 'monster',
  mopar: 'mopar',
  mormon: 'mormon',
  mortgage: 'mortgage',
  moscow: 'moscow',
  moto: 'moto',
  motorcycles: 'motorcycles',
  mov: 'mov',
  movie: 'movie',
  movistar: 'movistar',
  mp: 'mp',
  mq: 'mq',
  mr: 'mr',
  ms: 'ms',
  msd: 'msd',
  mt: 'mt',
  mtn: 'mtn',
  mtr: 'mtr',
  mu: 'mu',
  museum: 'museum',
  mutual: 'mutual',
  mv: 'mv',
  mw: 'mw',
  mx: 'mx',
  my: 'my',
  mz: 'mz',
  na: 'na',
  nab: 'nab',
  nadex: 'nadex',
  nagoya: 'nagoya',
  name: 'name',
  nationwide: 'nationwide',
  natura: 'natura',
  navy: 'navy',
  nba: 'nba',
  nc: 'nc',
  ne: 'ne',
  nec: 'nec',
  net: 'net',
  netbank: 'netbank',
  netflix: 'netflix',
  network: 'network',
  neustar: 'neustar',
  new: 'new',
  newholland: 'newholland',
  news: 'news',
  next: 'next',
  nextdirect: 'nextdirect',
  nexus: 'nexus',
  nf: 'nf',
  nfl: 'nfl',
  ng: 'ng',
  ngo: 'ngo',
  nhk: 'nhk',
  ni: 'ni',
  nico: 'nico',
  nike: 'nike',
  nikon: 'nikon',
  ninja: 'ninja',
  nissan: 'nissan',
  nissay: 'nissay',
  nl: 'nl',
  no: 'no',
  nokia: 'nokia',
  northwesternmutual: 'northwesternmutual',
  norton: 'norton',
  now: 'now',
  nowruz: 'nowruz',
  nowtv: 'nowtv',
  np: 'np',
  nr: 'nr',
  nra: 'nra',
  nrw: 'nrw',
  ntt: 'ntt',
  nu: 'nu',
  nyc: 'nyc',
  nz: 'nz',
  obi: 'obi',
  observer: 'observer',
  off: 'off',
  office: 'office',
  okinawa: 'okinawa',
  olayan: 'olayan',
  olayangroup: 'olayangroup',
  oldnavy: 'oldnavy',
  ollo: 'ollo',
  om: 'om',
  omega: 'omega',
  one: 'one',
  ong: 'ong',
  onl: 'onl',
  online: 'online',
  onyourside: 'onyourside',
  ooo: 'ooo',
  open: 'open',
  oracle: 'oracle',
  orange: 'orange',
  org: 'org',
  organic: 'organic',
  origins: 'origins',
  osaka: 'osaka',
  otsuka: 'otsuka',
  ott: 'ott',
  ovh: 'ovh',
  pa: 'pa',
  page: 'page',
  panasonic: 'panasonic',
  paris: 'paris',
  pars: 'pars',
  partners: 'partners',
  parts: 'parts',
  party: 'party',
  passagens: 'passagens',
  pay: 'pay',
  pccw: 'pccw',
  pe: 'pe',
  pet: 'pet',
  pf: 'pf',
  pfizer: 'pfizer',
  pg: 'pg',
  ph: 'ph',
  pharmacy: 'pharmacy',
  phd: 'phd',
  philips: 'philips',
  phone: 'phone',
  photo: 'photo',
  photography: 'photography',
  photos: 'photos',
  physio: 'physio',
  piaget: 'piaget',
  pics: 'pics',
  pictet: 'pictet',
  pictures: 'pictures',
  pid: 'pid',
  pin: 'pin',
  ping: 'ping',
  pink: 'pink',
  pioneer: 'pioneer',
  pizza: 'pizza',
  pk: 'pk',
  pl: 'pl',
  place: 'place',
  play: 'play',
  playstation: 'playstation',
  plumbing: 'plumbing',
  plus: 'plus',
  pm: 'pm',
  pn: 'pn',
  pnc: 'pnc',
  pohl: 'pohl',
  poker: 'poker',
  politie: 'politie',
  porn: 'porn',
  post: 'post',
  pr: 'pr',
  pramerica: 'pramerica',
  praxi: 'praxi',
  press: 'press',
  prime: 'prime',
  pro: 'pro',
  prod: 'prod',
  productions: 'productions',
  prof: 'prof',
  progressive: 'progressive',
  promo: 'promo',
  properties: 'properties',
  property: 'property',
  protection: 'protection',
  pru: 'pru',
  prudential: 'prudential',
  ps: 'ps',
  pt: 'pt',
  pub: 'pub',
  pw: 'pw',
  pwc: 'pwc',
  py: 'py',
  qa: 'qa',
  qpon: 'qpon',
  quebec: 'quebec',
  quest: 'quest',
  qvc: 'qvc',
  racing: 'racing',
  radio: 'radio',
  raid: 'raid',
  re: 're',
  read: 'read',
  realestate: 'realestate',
  realtor: 'realtor',
  realty: 'realty',
  recipes: 'recipes',
  red: 'red',
  redstone: 'redstone',
  redumbrella: 'redumbrella',
  rehab: 'rehab',
  reise: 'reise',
  reisen: 'reisen',
  reit: 'reit',
  reliance: 'reliance',
  ren: 'ren',
  rent: 'rent',
  rentals: 'rentals',
  repair: 'repair',
  report: 'report',
  republican: 'republican',
  rest: 'rest',
  restaurant: 'restaurant',
  review: 'review',
  reviews: 'reviews',
  rexroth: 'rexroth',
  rich: 'rich',
  richardli: 'richardli',
  ricoh: 'ricoh',
  rightathome: 'rightathome',
  ril: 'ril',
  rio: 'rio',
  rip: 'rip',
  rmit: 'rmit',
  ro: 'ro',
  rocher: 'rocher',
  rocks: 'rocks',
  rodeo: 'rodeo',
  rogers: 'rogers',
  room: 'room',
  rs: 'rs',
  rsvp: 'rsvp',
  ru: 'ru',
  rugby: 'rugby',
  ruhr: 'ruhr',
  run: 'run',
  rw: 'rw',
  rwe: 'rwe',
  ryukyu: 'ryukyu',
  sa: 'sa',
  saarland: 'saarland',
  safe: 'safe',
  safety: 'safety',
  sakura: 'sakura',
  sale: 'sale',
  salon: 'salon',
  samsclub: 'samsclub',
  samsung: 'samsung',
  sandvik: 'sandvik',
  sandvikcoromant: 'sandvikcoromant',
  sanofi: 'sanofi',
  sap: 'sap',
  sarl: 'sarl',
  sas: 'sas',
  save: 'save',
  saxo: 'saxo',
  sb: 'sb',
  sbi: 'sbi',
  sbs: 'sbs',
  sc: 'sc',
  sca: 'sca',
  scb: 'scb',
  schaeffler: 'schaeffler',
  schmidt: 'schmidt',
  scholarships: 'scholarships',
  school: 'school',
  schule: 'schule',
  schwarz: 'schwarz',
  science: 'science',
  scjohnson: 'scjohnson',
  scor: 'scor',
  scot: 'scot',
  sd: 'sd',
  se: 'se',
  search: 'search',
  seat: 'seat',
  secure: 'secure',
  security: 'security',
  seek: 'seek',
  select: 'select',
  sener: 'sener',
  services: 'services',
  ses: 'ses',
  seven: 'seven',
  sew: 'sew',
  sex: 'sex',
  sexy: 'sexy',
  sfr: 'sfr',
  sg: 'sg',
  sh: 'sh',
  shangrila: 'shangrila',
  sharp: 'sharp',
  shaw: 'shaw',
  shell: 'shell',
  shia: 'shia',
  shiksha: 'shiksha',
  shoes: 'shoes',
  shop: 'shop',
  shopping: 'shopping',
  shouji: 'shouji',
  show: 'show',
  showtime: 'showtime',
  shriram: 'shriram',
  si: 'si',
  silk: 'silk',
  sina: 'sina',
  singles: 'singles',
  site: 'site',
  sj: 'sj',
  sk: 'sk',
  ski: 'ski',
  skin: 'skin',
  sky: 'sky',
  skype: 'skype',
  sl: 'sl',
  sling: 'sling',
  sm: 'sm',
  smart: 'smart',
  smile: 'smile',
  sn: 'sn',
  sncf: 'sncf',
  so: 'so',
  soccer: 'soccer',
  social: 'social',
  softbank: 'softbank',
  software: 'software',
  sohu: 'sohu',
  solar: 'solar',
  solutions: 'solutions',
  song: 'song',
  sony: 'sony',
  soy: 'soy',
  space: 'space',
  sport: 'sport',
  spot: 'spot',
  spreadbetting: 'spreadbetting',
  sr: 'sr',
  srl: 'srl',
  srt: 'srt',
  ss: 'ss',
  st: 'st',
  stada: 'stada',
  staples: 'staples',
  star: 'star',
  starhub: 'starhub',
  statebank: 'statebank',
  statefarm: 'statefarm',
  stc: 'stc',
  stcgroup: 'stcgroup',
  stockholm: 'stockholm',
  storage: 'storage',
  store: 'store',
  stream: 'stream',
  studio: 'studio',
  study: 'study',
  style: 'style',
  su: 'su',
  sucks: 'sucks',
  supplies: 'supplies',
  supply: 'supply',
  support: 'support',
  surf: 'surf',
  surgery: 'surgery',
  suzuki: 'suzuki',
  sv: 'sv',
  swatch: 'swatch',
  swiftcover: 'swiftcover',
  swiss: 'swiss',
  sx: 'sx',
  sy: 'sy',
  sydney: 'sydney',
  symantec: 'symantec',
  systems: 'systems',
  sz: 'sz',
  tab: 'tab',
  taipei: 'taipei',
  talk: 'talk',
  taobao: 'taobao',
  target: 'target',
  tatamotors: 'tatamotors',
  tatar: 'tatar',
  tattoo: 'tattoo',
  tax: 'tax',
  taxi: 'taxi',
  tc: 'tc',
  tci: 'tci',
  td: 'td',
  tdk: 'tdk',
  team: 'team',
  tech: 'tech',
  technology: 'technology',
  tel: 'tel',
  telefonica: 'telefonica',
  temasek: 'temasek',
  tennis: 'tennis',
  teva: 'teva',
  tf: 'tf',
  tg: 'tg',
  th: 'th',
  thd: 'thd',
  theater: 'theater',
  theatre: 'theatre',
  tiaa: 'tiaa',
  tickets: 'tickets',
  tienda: 'tienda',
  tiffany: 'tiffany',
  tips: 'tips',
  tires: 'tires',
  tirol: 'tirol',
  tj: 'tj',
  tjmaxx: 'tjmaxx',
  tjx: 'tjx',
  tk: 'tk',
  tkmaxx: 'tkmaxx',
  tl: 'tl',
  tm: 'tm',
  tmall: 'tmall',
  tn: 'tn',
  to: 'to',
  today: 'today',
  tokyo: 'tokyo',
  tools: 'tools',
  top: 'top',
  toray: 'toray',
  toshiba: 'toshiba',
  total: 'total',
  tours: 'tours',
  town: 'town',
  toyota: 'toyota',
  toys: 'toys',
  tr: 'tr',
  trade: 'trade',
  trading: 'trading',
  training: 'training',
  travel: 'travel',
  travelchannel: 'travelchannel',
  travelers: 'travelers',
  travelersinsurance: 'travelersinsurance',
  trust: 'trust',
  trv: 'trv',
  tt: 'tt',
  tube: 'tube',
  tui: 'tui',
  tunes: 'tunes',
  tushu: 'tushu',
  tv: 'tv',
  tvs: 'tvs',
  tw: 'tw',
  tz: 'tz',
  ua: 'ua',
  ubank: 'ubank',
  ubs: 'ubs',
  uconnect: 'uconnect',
  ug: 'ug',
  uk: 'uk',
  unicom: 'unicom',
  university: 'university',
  uno: 'uno',
  uol: 'uol',
  ups: 'ups',
  us: 'us',
  uy: 'uy',
  uz: 'uz',
  va: 'va',
  vacations: 'vacations',
  vana: 'vana',
  vanguard: 'vanguard',
  vc: 'vc',
  ve: 've',
  vegas: 'vegas',
  ventures: 'ventures',
  verisign: 'verisign',
  versicherung: 'versicherung',
  vet: 'vet',
  vg: 'vg',
  vi: 'vi',
  viajes: 'viajes',
  video: 'video',
  vig: 'vig',
  viking: 'viking',
  villas: 'villas',
  vin: 'vin',
  vip: 'vip',
  virgin: 'virgin',
  visa: 'visa',
  vision: 'vision',
  vistaprint: 'vistaprint',
  viva: 'viva',
  vivo: 'vivo',
  vlaanderen: 'vlaanderen',
  vn: 'vn',
  vodka: 'vodka',
  volkswagen: 'volkswagen',
  volvo: 'volvo',
  vote: 'vote',
  voting: 'voting',
  voto: 'voto',
  voyage: 'voyage',
  vu: 'vu',
  vuelos: 'vuelos',
  wales: 'wales',
  walmart: 'walmart',
  walter: 'walter',
  wang: 'wang',
  wanggou: 'wanggou',
  warman: 'warman',
  watch: 'watch',
  watches: 'watches',
  weather: 'weather',
  weatherchannel: 'weatherchannel',
  webcam: 'webcam',
  weber: 'weber',
  website: 'website',
  wed: 'wed',
  wedding: 'wedding',
  weibo: 'weibo',
  weir: 'weir',
  wf: 'wf',
  whoswho: 'whoswho',
  wien: 'wien',
  wiki: 'wiki',
  williamhill: 'williamhill',
  win: 'win',
  windows: 'windows',
  wine: 'wine',
  winners: 'winners',
  wme: 'wme',
  wolterskluwer: 'wolterskluwer',
  woodside: 'woodside',
  work: 'work',
  works: 'works',
  world: 'world',
  wow: 'wow',
  ws: 'ws',
  wtc: 'wtc',
  wtf: 'wtf',
  xbox: 'xbox',
  xerox: 'xerox',
  xfinity: 'xfinity',
  xihuan: 'xihuan',
  xin: 'xin',
  'xn--11b4c3d': 'xn--11b4c3d',
  'xn--1ck2e1b': 'xn--1ck2e1b',
  'xn--1qqw23a': 'xn--1qqw23a',
  'xn--2scrj9c': 'xn--2scrj9c',
  'xn--30rr7y': 'xn--30rr7y',
  'xn--3bst00m': 'xn--3bst00m',
  'xn--3ds443g': 'xn--3ds443g',
  'xn--3e0b707e': 'xn--3e0b707e',
  'xn--3hcrj9c': 'xn--3hcrj9c',
  'xn--3oq18vl8pn36a': 'xn--3oq18vl8pn36a',
  'xn--3pxu8k': 'xn--3pxu8k',
  'xn--42c2d9a': 'xn--42c2d9a',
  'xn--45br5cyl': 'xn--45br5cyl',
  'xn--45brj9c': 'xn--45brj9c',
  'xn--45q11c': 'xn--45q11c',
  'xn--4gbrim': 'xn--4gbrim',
  'xn--54b7fta0cc': 'xn--54b7fta0cc',
  'xn--55qw42g': 'xn--55qw42g',
  'xn--55qx5d': 'xn--55qx5d',
  'xn--5su34j936bgsg': 'xn--5su34j936bgsg',
  'xn--5tzm5g': 'xn--5tzm5g',
  'xn--6frz82g': 'xn--6frz82g',
  'xn--6qq986b3xl': 'xn--6qq986b3xl',
  'xn--80adxhks': 'xn--80adxhks',
  'xn--80ao21a': 'xn--80ao21a',
  'xn--80aqecdr1a': 'xn--80aqecdr1a',
  'xn--80asehdb': 'xn--80asehdb',
  'xn--80aswg': 'xn--80aswg',
  'xn--8y0a063a': 'xn--8y0a063a',
  'xn--90a3ac': 'xn--90a3ac',
  'xn--90ae': 'xn--90ae',
  'xn--90ais': 'xn--90ais',
  'xn--9dbq2a': 'xn--9dbq2a',
  'xn--9et52u': 'xn--9et52u',
  'xn--9krt00a': 'xn--9krt00a',
  'xn--b4w605ferd': 'xn--b4w605ferd',
  'xn--bck1b9a5dre4c': 'xn--bck1b9a5dre4c',
  'xn--c1avg': 'xn--c1avg',
  'xn--c2br7g': 'xn--c2br7g',
  'xn--cck2b3b': 'xn--cck2b3b',
  'xn--cg4bki': 'xn--cg4bki',
  'xn--clchc0ea0b2g2a9gcd': 'xn--clchc0ea0b2g2a9gcd',
  'xn--czr694b': 'xn--czr694b',
  'xn--czrs0t': 'xn--czrs0t',
  'xn--czru2d': 'xn--czru2d',
  'xn--d1acj3b': 'xn--d1acj3b',
  'xn--d1alf': 'xn--d1alf',
  'xn--e1a4c': 'xn--e1a4c',
  'xn--eckvdtc9d': 'xn--eckvdtc9d',
  'xn--efvy88h': 'xn--efvy88h',
  'xn--estv75g': 'xn--estv75g',
  'xn--fct429k': 'xn--fct429k',
  'xn--fhbei': 'xn--fhbei',
  'xn--fiq228c5hs': 'xn--fiq228c5hs',
  'xn--fiq64b': 'xn--fiq64b',
  'xn--fiqs8s': 'xn--fiqs8s',
  'xn--fiqz9s': 'xn--fiqz9s',
  'xn--fjq720a': 'xn--fjq720a',
  'xn--flw351e': 'xn--flw351e',
  'xn--fpcrj9c3d': 'xn--fpcrj9c3d',
  'xn--fzc2c9e2c': 'xn--fzc2c9e2c',
  'xn--fzys8d69uvgm': 'xn--fzys8d69uvgm',
  'xn--g2xx48c': 'xn--g2xx48c',
  'xn--gckr3f0f': 'xn--gckr3f0f',
  'xn--gecrj9c': 'xn--gecrj9c',
  'xn--gk3at1e': 'xn--gk3at1e',
  'xn--h2breg3eve': 'xn--h2breg3eve',
  'xn--h2brj9c': 'xn--h2brj9c',
  'xn--h2brj9c8c': 'xn--h2brj9c8c',
  'xn--hxt814e': 'xn--hxt814e',
  'xn--i1b6b1a6a2e': 'xn--i1b6b1a6a2e',
  'xn--imr513n': 'xn--imr513n',
  'xn--io0a7i': 'xn--io0a7i',
  'xn--j1aef': 'xn--j1aef',
  'xn--j1amh': 'xn--j1amh',
  'xn--j6w193g': 'xn--j6w193g',
  'xn--jlq61u9w7b': 'xn--jlq61u9w7b',
  'xn--jvr189m': 'xn--jvr189m',
  'xn--kcrx77d1x4a': 'xn--kcrx77d1x4a',
  'xn--kprw13d': 'xn--kprw13d',
  'xn--kpry57d': 'xn--kpry57d',
  'xn--kpu716f': 'xn--kpu716f',
  'xn--kput3i': 'xn--kput3i',
  'xn--l1acc': 'xn--l1acc',
  'xn--lgbbat1ad8j': 'xn--lgbbat1ad8j',
  'xn--mgb9awbf': 'xn--mgb9awbf',
  'xn--mgba3a3ejt': 'xn--mgba3a3ejt',
  'xn--mgba3a4f16a': 'xn--mgba3a4f16a',
  'xn--mgba7c0bbn0a': 'xn--mgba7c0bbn0a',
  'xn--mgbaakc7dvf': 'xn--mgbaakc7dvf',
  'xn--mgbaam7a8h': 'xn--mgbaam7a8h',
  'xn--mgbab2bd': 'xn--mgbab2bd',
  'xn--mgbah1a3hjkrd': 'xn--mgbah1a3hjkrd',
  'xn--mgbai9azgqp6j': 'xn--mgbai9azgqp6j',
  'xn--mgbayh7gpa': 'xn--mgbayh7gpa',
  'xn--mgbb9fbpob': 'xn--mgbb9fbpob',
  'xn--mgbbh1a': 'xn--mgbbh1a',
  'xn--mgbbh1a71e': 'xn--mgbbh1a71e',
  'xn--mgbc0a9azcg': 'xn--mgbc0a9azcg',
  'xn--mgbca7dzdo': 'xn--mgbca7dzdo',
  'xn--mgberp4a5d4ar': 'xn--mgberp4a5d4ar',
  'xn--mgbgu82a': 'xn--mgbgu82a',
  'xn--mgbi4ecexp': 'xn--mgbi4ecexp',
  'xn--mgbpl2fh': 'xn--mgbpl2fh',
  'xn--mgbt3dhd': 'xn--mgbt3dhd',
  'xn--mgbtx2b': 'xn--mgbtx2b',
  'xn--mgbx4cd0ab': 'xn--mgbx4cd0ab',
  'xn--mix891f': 'xn--mix891f',
  'xn--mk1bu44c': 'xn--mk1bu44c',
  'xn--mxtq1m': 'xn--mxtq1m',
  'xn--ngbc5azd': 'xn--ngbc5azd',
  'xn--ngbe9e0a': 'xn--ngbe9e0a',
  'xn--ngbrx': 'xn--ngbrx',
  'xn--node': 'xn--node',
  'xn--nqv7f': 'xn--nqv7f',
  'xn--nqv7fs00ema': 'xn--nqv7fs00ema',
  'xn--nyqy26a': 'xn--nyqy26a',
  'xn--o3cw4h': 'xn--o3cw4h',
  'xn--ogbpf8fl': 'xn--ogbpf8fl',
  'xn--otu796d': 'xn--otu796d',
  'xn--p1acf': 'xn--p1acf',
  'xn--p1ai': 'xn--p1ai',
  'xn--pbt977c': 'xn--pbt977c',
  'xn--pgbs0dh': 'xn--pgbs0dh',
  'xn--pssy2u': 'xn--pssy2u',
  'xn--q9jyb4c': 'xn--q9jyb4c',
  'xn--qcka1pmc': 'xn--qcka1pmc',
  'xn--qxam': 'xn--qxam',
  'xn--rhqv96g': 'xn--rhqv96g',
  'xn--rovu88b': 'xn--rovu88b',
  'xn--rvc1e0am3e': 'xn--rvc1e0am3e',
  'xn--s9brj9c': 'xn--s9brj9c',
  'xn--ses554g': 'xn--ses554g',
  'xn--t60b56a': 'xn--t60b56a',
  'xn--tckwe': 'xn--tckwe',
  'xn--tiq49xqyj': 'xn--tiq49xqyj',
  'xn--unup4y': 'xn--unup4y',
  'xn--vermgensberater-ctb': 'xn--vermgensberater-ctb',
  'xn--vermgensberatung-pwb': 'xn--vermgensberatung-pwb',
  'xn--vhquv': 'xn--vhquv',
  'xn--vuq861b': 'xn--vuq861b',
  'xn--w4r85el8fhu5dnra': 'xn--w4r85el8fhu5dnra',
  'xn--w4rs40l': 'xn--w4rs40l',
  'xn--wgbh1c': 'xn--wgbh1c',
  'xn--wgbl6a': 'xn--wgbl6a',
  'xn--xhq521b': 'xn--xhq521b',
  'xn--xkc2al3hye2a': 'xn--xkc2al3hye2a',
  'xn--xkc2dl3a5ee0h': 'xn--xkc2dl3a5ee0h',
  'xn--y9a3aq': 'xn--y9a3aq',
  'xn--yfro4i67o': 'xn--yfro4i67o',
  'xn--ygbi2ammx': 'xn--ygbi2ammx',
  'xn--zfr164b': 'xn--zfr164b',
  xxx: 'xxx',
  xyz: 'xyz',
  yachts: 'yachts',
  yahoo: 'yahoo',
  yamaxun: 'yamaxun',
  yandex: 'yandex',
  ye: 'ye',
  yodobashi: 'yodobashi',
  yoga: 'yoga',
  yokohama: 'yokohama',
  you: 'you',
  youtube: 'youtube',
  yt: 'yt',
  yun: 'yun',
  za: 'za',
  zappos: 'zappos',
  zara: 'zara',
  zero: 'zero',
  zip: 'zip',
  zm: 'zm',
  zone: 'zone',
  zuerich: 'zuerich',
  zw: 'zw'
}
