import startCase from 'lodash/startCase'

import firebase from 'utils/firebase'
import { SET_INITIAL_URL } from 'modules/url/actions'
import {
  createOrFetchUser,
  clearAuthUser,
  openAuthModal
} from 'modules/auth/actions'
import { doneLoadingAuthUser } from 'modules/session/actions'
import { isMissingRequiredUserInfo } from 'utils/users'

const worker = (store: any) => (next: any) => (action: any) => {
  switch (action.type) {
    case SET_INITIAL_URL:
      next(action)
      syncAuth(store)
      break

    default:
      next(action)
  }
}

export default worker

const modalDetails = {
  longSignUpTitle: 'Missing details',
  shortSignUpTitle: 'Missing details'
}

export const syncAuth = async (store: any) => {
  firebase.auth().onAuthStateChanged(async (user) => {
    const { isBootstrapped } = store.getState().auth

    if (user && !isBootstrapped) {
      const idToken = await user.getIdToken()

      const displayName = user.displayName
      const indexOfSpace = displayName!.indexOf(' ')
      const firstName = startCase(displayName!.substring(0, indexOfSpace))
      const lastName = startCase(displayName!.substring(indexOfSpace + 1))

      if (isMissingRequiredUserInfo(user.email, firstName, lastName)) {
        store.dispatch(openAuthModal(modalDetails))
      } else {
        const user = await store.dispatch(createOrFetchUser(idToken))

        if (!user.phone) {
          store.dispatch(openAuthModal(modalDetails))
        }
      }
    } else if (!user) {
      store.dispatch(doneLoadingAuthUser())
      store.dispatch(clearAuthUser())
    }
  })
}
