import get from 'lodash/get'

import { RECEIVED_AUTH_USER } from '../modules/auth/actions'
import {
  RECEIVED_USER_COOKIE,
  receivedCookieUser,
  updateUserCookie,
  updateFirestoreUser,
  doneLoadingCookieUser
} from 'modules/session/actions'
import { firestoreCollections } from '../utils/firebase'

const worker = (store) => (next) => (action) => {
  switch (action.type) {
    case RECEIVED_USER_COOKIE:
      next(action)
      if (
        (get(action, 'userCookie.email') || get(action, 'userCookie.id')) &&
        !unsubscribe
      ) {
        startSyncingCookieUser(store, action.userCookie, action.noTracking)
      } else {
        store.dispatch(doneLoadingCookieUser())
      }

      break

    case RECEIVED_AUTH_USER:
      next(action)
      stopSyncingCookieUser()
      break

    default:
      next(action)
  }
}

export default worker

let unsubscribe

const COOKIE_TO_FIRESTORE_PROPERTIES = [
  'userRegionPreference',
  'notificationForSearches'
]

export function startSyncingCookieUser(store, userCookie, noTracking) {
  const where = userCookie.id
    ? ['id', '==', userCookie.id]
    : ['email', '==', userCookie.email]
  unsubscribe = firestoreCollections.usersRef
    .where(...where)
    .onSnapshot((query) => {
      if (query.docs.length) {
        const firestoreUserData = query.docs[0].data()
        store.dispatch(receivedCookieUser(firestoreUserData))

        if (!noTracking) {
          // Sync cookie to firestore
          const cookieToFirestoreProp = COOKIE_TO_FIRESTORE_PROPERTIES.reduce(
            (acc, key) => {
              if (
                firestoreUserData[key] !== userCookie[key] &&
                userCookie[key] !== undefined
              ) {
                acc[key] = userCookie[key]
              }
              return acc
            },
            {}
          )

          if (Object.keys(cookieToFirestoreProp).length) {
            store.dispatch(
              updateFirestoreUser(cookieToFirestoreProp, firestoreUserData.id)
            )
          }

          // This is so we can use the firebase id going forward rather than do email looks etc
          store.dispatch(
            updateUserCookie({
              id: firestoreUserData.id
            })
          )
        }
      } else {
        store.dispatch(doneLoadingCookieUser())
      }
    })
}

export function stopSyncingCookieUser() {
  unsubscribe && unsubscribe()
}
