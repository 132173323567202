import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset, reduxForm, Field } from 'redux-form'
import { Form, FormGroup, Button, Alert } from 'reactstrap'
import trim from 'lodash/trim'
import each from 'lodash/each'

import firebase from '../../utils/firebase'
import { required, email, renderField } from '../../utils/forms'

import '../../styles/auth-modal.css'

class ResetPasswordEmailForm extends Component {
  static propTypes = {
    reset: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }

  state = {
    submitting: false,
    error: false,
    success: false
  }

  submit = async (data) => {
    each(data, (value, key) => {
      data[key] = trim(value)
    })

    try {
      this.setState({ submitting: true })

      await firebase.auth().sendPasswordResetEmail(data.email.toLowerCase())
      this.setState({ submitting: false, success: true })
    } catch (err) {
      if (
        err.message.includes(
          'There is no user record corresponding to this identifier'
        )
      ) {
        err.message =
          "We're unable to find an account with that email address. Please try a different email or sign up for a new account."
      }
      this.setState({
        error: err.message,
        submitting: false
      })
    }
  }

  render() {
    const { valid, handleSubmit } = this.props

    return (
      <>
        {this.state.error && <Alert color='danger'>{this.state.error}</Alert>}

        {this.state.success ? (
          <>
            <Alert color='info'>
              A password reset email has been sent. Please follow the
              instructions in your email to finish resetting your password.
            </Alert>
            <Button
              type='button'
              className='auth-form-submit-button'
              onClick={() => this.props.goBack()}
            >
              Close
            </Button>
          </>
        ) : (
          <Form onSubmit={handleSubmit(this.submit)}>
            <FormGroup>
              <Field
                validate={[required, email]}
                component={renderField}
                placeholder='Email'
                type='email'
                name='email'
              />
            </FormGroup>

            <Button
              type='submit'
              className='auth-form-submit-button'
              disabled={!valid || this.state.submitting}
              onClick={handleSubmit(this.submit)}
            >
              Reset Password
            </Button>

            <Button
              type='button'
              style={{
                width: '100%',
                height: '4rem',
                fontSize: '1.1rem !important',
                marginTop: '1.5rem'
              }}
              onClick={() => this.props.goBack()}
            >
              Go Back
            </Button>
          </Form>
        )}
      </>
    )
  }
}

const mapDispatchToProps = {
  reset: () => reset('resetPasswordEmailForm')
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'resetPasswordEmailForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })(ResetPasswordEmailForm)
)
