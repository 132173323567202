import {
  getUserCookie,
  updateUserCookie,
  receivedUserCookie
} from '../session/actions'

export const CHANGED_URL_PATH = 'CHANGED_URL_PATH'
export const SET_INITIAL_URL = 'SET_INITIAL_URL'
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'

export function setInitialUrl(landingUrl, searchParams) {
  return (dispatch) => {
    const firestoreUserId =
      searchParams.get('firestore_user_id') || searchParams.get('id')
    const noTracking = !!searchParams.get('no_events')
    if (firestoreUserId) {
      if (noTracking) {
        dispatch(
          receivedUserCookie(
            {
              id: firestoreUserId
            },
            noTracking
          )
        )
      } else {
        dispatch(
          updateUserCookie({
            id: firestoreUserId
          })
        )
      }
    } else {
      dispatch(getUserCookie())
    }

    return dispatch({
      type: SET_INITIAL_URL,
      landingUrl,
      searchParams
    })
  }
}

export function setSearchParams(searchParams) {
  return {
    type: SET_SEARCH_PARAMS,
    searchParams
  }
}

export function changedUrlPath(path) {
  return {
    type: CHANGED_URL_PATH,
    path
  }
}
