import React, { memo } from 'react'
import BodyText from '../Text/Body'
import CircleCheckmarkOutline from '../Icons/CircleCheckmarkOutline'
import HeaderText from '../Text/Header'
import Lock from '../Icons/Lock'

function StepContainer({
  title = '',
  description = '',
  steps = [],
  onStart = () => null,
  isLocked = false
}) {
  const allComplete = steps.reduce(
    (acc, { isComplete }) => acc && isComplete,
    true
  )
  const atLeastOneComplete = steps.reduce(
    (acc, { isComplete }) => acc || isComplete,
    false
  )

  return (
    <div
      style={{
        textAlign: 'left',
        border: `1px solid ${allComplete ? '#6EBA83' : '#EEEDEA'} `,
        borderRadius: '5px',
        padding: '16px',
        margin: '20px 0',
        position: 'relative',
        backgroundColor: isLocked ? '#F9F8F7' : '#fff'
      }}
    >
      <HeaderText
        style={{
          color: isLocked ? '#BFBDBB' : ''
        }}
        size='h4'
      >
        {title}
      </HeaderText>
      <BodyText
        color={isLocked ? '#BFBDBB' : '#55545A'}
        style={{ opacity: '0.75' }}
        size='s'
      >
        {description}
      </BodyText>
      <div>
        {steps.map(({ name, isComplete }) => (
          <div
            id={name}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center'
            }}
          >
            <span style={{ marginRight: '5px' }}>
              <CircleCheckmarkOutline
                color={isComplete ? '#6EBA83' : '#BFBDBB'}
              />
            </span>
            <BodyText
              size='s'
              lineHeight='15px'
              color={isComplete ? '#6EBA83' : '#BFBDBB'}
            >
              {name}
            </BodyText>
          </div>
        ))}
      </div>
      <BodyText
        color='#00CECC'
        size='s'
        onClick={!isLocked ? onStart : () => null}
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          fontWeight: '600',
          cursor: 'pointer'
        }}
      >
        {isLocked && <Lock />}
        {!isLocked && !!atLeastOneComplete && !allComplete && 'Edit'}
        {!isLocked && !atLeastOneComplete && !allComplete && 'Start'}
      </BodyText>
    </div>
  )
}

export default memo(StepContainer)
