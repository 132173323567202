import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset, reduxForm, Field } from 'redux-form'
import { Form, FormGroup, Button, Alert } from 'reactstrap'
import startCase from 'lodash/startCase'
import each from 'lodash/each'
import trim from 'lodash/trim'
import * as Sentry from '@sentry/react'

import {
  required,
  phoneNumber,
  normalizePhone,
  renderField,
  email,
  onlyNums
} from '../../utils/forms'
import {
  closeAuthModal,
  updateUser,
  createOrFetchUser
} from '../../modules/auth/actions'
import '../../styles/auth-modal.css'
import styled from 'styled-components'
import firebase from '../../utils/firebase'

const StyledH4 = styled.h4`
  text-align: center;
  margin-bottom: 2rem;
`

class InitialDetailsForm extends Component {
  static propTypes = {
    reset: PropTypes.func.isRequired,
    closeAuthModal: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    createOrFetchUser: PropTypes.func.isRequired,
    detailsError: PropTypes.string,
    initialValues: PropTypes.object
  }

  state = {
    submitting: false,
    error: false,
    askForPhone: false
  }

  UNSAFE_componentWillMount() {
    if (this.props.detailsError) {
      this.setState({ error: this.props.detailsError })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.detailsError) {
      this.setState({ error: nextProps.detailsError })
    }
  }

  submit = async (data) => {
    each(data, (value, key) => {
      data[key] = trim(value)
    })

    try {
      this.setState({ submitting: true, error: false })

      const firebaseUser = firebase.auth().currentUser
      const idToken = await firebaseUser.getIdToken()

      if (!this.state.askForPhone) {
        const firstName = startCase(data.firstName)
        const lastName = startCase(data.lastName)

        const displayName = `${firstName} ${lastName}`

        if (displayName !== firebaseUser.displayName) {
          await firebaseUser.updateProfile({
            displayName
          })
        }

        if (!firebaseUser.email) {
          const email = data.email.toLowerCase()

          try {
            await firebaseUser.updateEmail(email)
            firebaseUser.sendEmailVerification()
          } catch (err) {
            Sentry.captureMessage(err)
          }
        }

        let user = await this.props.createOrFetchUser(idToken)

        const update = {}

        if (user.first_name !== firstName) {
          update.first_name = firstName
        }

        if (user.last_name !== lastName) {
          update.last_name = lastName
        }

        user = await this.props.updateUser(update)

        if (!user.phone) {
          this.setState({ askForPhone: true, submitting: false })
          return
        }
      } else {
        const phone = `+${onlyNums(data.phone)}`

        const user = await this.props.createOrFetchUser(idToken)

        if (user.phone !== phone) {
          await this.props.updateUser({ phone })
        }
      }

      this.props.closeAuthModal(true)
    } catch (err) {
      this.setState({
        error: this.state.askForPhone
          ? 'That phone number is already in use.'
          : 'That email address is already in use.',
        submitting: false
      })
    }
  }

  render() {
    const { valid, handleSubmit, initialValues } = this.props

    return (
      <>
        {this.state.error && <Alert color='danger'>{this.state.error}</Alert>}
        <StyledH4>
          Please confirm the following information to continue.
        </StyledH4>
        <Form onSubmit={handleSubmit(this.submit)}>
          {!this.state.askForPhone && (
            <>
              <FormGroup>
                <Field
                  validate={[required]}
                  component={renderField}
                  placeholder='First name'
                  type='text'
                  name='firstName'
                />
              </FormGroup>
              <FormGroup>
                <Field
                  validate={[required]}
                  component={renderField}
                  placeholder='Last name'
                  type='text'
                  name='lastName'
                />
              </FormGroup>
              <FormGroup>
                <Field
                  validate={[required, email]}
                  component={renderField}
                  placeholder='Email'
                  type={initialValues.email ? 'hidden' : 'email'}
                  name='email'
                />
              </FormGroup>
            </>
          )}
          {this.state.askForPhone && (
            <FormGroup>
              <Field
                validate={[required, phoneNumber]}
                component={renderField}
                placeholder='Phone number'
                normalize={normalizePhone}
                type='tel'
                name='phone'
              />
            </FormGroup>
          )}

          <Button
            className='auth-form-submit-button'
            disabled={!valid || this.state.submitting}
            type='submit'
          >
            Continue
          </Button>
        </Form>
      </>
    )
  }
}

const mapDispatchToProps = {
  reset: () => reset('initialDetailsForm'),
  closeAuthModal,
  updateUser,
  createOrFetchUser
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: 'initialDetailsForm',
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })(InitialDetailsForm)
)
