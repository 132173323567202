import React, { memo } from 'react'
import BodyText from '../Text/Body'
import HeaderText from '../Text/Header'

function InfoContainer({ title = '', description = '', note = '' }) {
  return (
    <div
      style={{
        textAlign: 'left',
        background: '#F3F7F7',
        borderRadius: '5px',
        padding: '16px',
        margin: '30px 0'
      }}
    >
      <BodyText
        style={{
          color: '#55545A',
          fontWeight: '600',
          opacity: 0.5
        }}
        size='s'
      >
        {note}
      </BodyText>
      <HeaderText
        style={{
          color: '#19778D',
          lineHeight: '24px',
          margin: '15px 0'
        }}
        size='h4'
      >
        {title}
      </HeaderText>
      <BodyText size='s'>{description}</BodyText>
    </div>
  )
}

export default memo(InfoContainer)
