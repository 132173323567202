import React, { memo } from 'react'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import HeaderText from 'components/Toolkit/Text/Header'
import ForwardChevron from 'components/Toolkit/Icons/ForwardChevron'

const StyledHeaderText = styled(HeaderText)`
  cursor: pointer;
  font-weight: 500;
`

const StyledIcon = styled.span`
  margin-left: 4px;
`

function DrillDown({ label, onClick, ...otherProps }) {
  return (
    <StyledHeaderText size='h4' onClick={onClick} {...otherProps}>
      {label}{' '}
      <StyledIcon>
        <ForwardChevron width='5' height='9' color={COLORS.PRIMARY.PLUM} />
      </StyledIcon>
    </StyledHeaderText>
  )
}

export default memo(DrillDown)
