import React, { useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import { Provider as UrqlProvider } from 'urql'
import { ConnectedRouter } from 'connected-react-router'
import 'intersection-observer'
import 'url-search-params-polyfill'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'
import Cookies from 'universal-cookie'

import { store, history } from './store'
import Routes from './routes'
import { facebookPixelId } from './config'
import 'utils/sentry'
import firebase from 'utils/firebase'
import './styles/core.scss'

import 'styles/react-responsive-carousel.css'
import ToastWrapper from 'components/ToastWrapper'
import reportWebVitals from 'reportWebVitals'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import { createUrqlClient } from 'utils/urql'
import { initializeDatadog } from 'datadog'

initializeDatadog()

if (facebookPixelId) {
  // eslint-disable-next-line no-undef
  window.fbq('init', facebookPixelId)
  // eslint-disable-next-line no-undef
  window.fbq('track', 'PageView')
}

const StyledAppContainer = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
`

const initialize = async () => {
  // The user is an admin from Hudson.
  // TODO: Disable whatever we need or figure out how to identify behavior done as an admin.
  const HUDSON_KEY = 'hudsonCustomToken'
  const cookies = new Cookies()
  const hudsonCustomToken = cookies.get(HUDSON_KEY)

  if (hudsonCustomToken) {
    cookies.remove(HUDSON_KEY)
    await firebase.auth().signInWithCustomToken(hudsonCustomToken)
    const user = await firebase.auth().currentUser
    // eslint-disable-next-line no-undef
    window.alert(
      `You are now logged in ${user.email}. Please proceed with caution!`
    )
  }
}

const App = () => {
  const urqlToken = useSelector((store) => store.urql?.token)

  const client = useMemo(() => {
    return createUrqlClient(urqlToken)
  }, [urqlToken])

  useEffect(() => {
    initialize()
  }, [])

  return (
    <UrqlProvider value={client}>
      <ConnectedRouter history={history}>
        <Div100vh>
          <StyledAppContainer id='layout-root'>
            <Routes />
            <ToastWrapper />
          </StyledAppContainer>
        </Div100vh>
      </ConnectedRouter>
    </UrqlProvider>
  )
}

const StoreWrapper = () => (
  <Provider store={store}>
    <App />
  </Provider>
)

// eslint-disable-next-line no-undef
ReactDOM.render(<StoreWrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
