import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const BASE = styled.p`
  color: #263135;
  font-family: Poppins;
  font-style: normal;
  font-feature-settings: 'liga' off;
`

const SIZES = {
  h1: styled(BASE)`
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    margin: 0 0 22px 0;
  `,
  h2: styled(BASE)`
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin: 0 0 22px 0;
  `,
  h3: styled(BASE)`
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    margin: 0 0 17px 0;
  `,
  h4: styled(BASE)`
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 9px 0;
  `,
  h5: styled(BASE)`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  `
}

function HeaderText({ size, ...otherProps }) {
  const Elm = SIZES[size]

  return <Elm {...otherProps} />
}

HeaderText.propTypes = {
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']),
  total: PropTypes.number
}

export default memo(HeaderText)
