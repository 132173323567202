import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import COLORS from 'components/Toolkit/colors'
import Label from 'components/Toolkit/Text/Label'
import BodyText from 'components/Toolkit/Text/Body'

const StyledCircle = styled.div`
  background: ${COLORS.PRIMARY.COCONUT};
  border: ${(props) =>
    props.isSelected
      ? `13px solid ${COLORS.PRIMARY.JELLO}`
      : `3px solid ${COLORS.LIGHT_NEUTRALS.MERCURY}`};
  box-sizing: border-box;
  border-radius: 100%;
  height: 25px;
  transition: border 100ms ease-in;
  width: 25px;
  cursor: pointer;
`

function RadioGroup({ label, value, options, onChange }) {
  return (
    <div>
      {label && <Label>{label}</Label>}
      {options.map(({ label, value: optionValue }, idx) => (
        <div
          key={idx}
          onClick={() => {
            onChange(optionValue)
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #CCC',
            borderRadius: '4px',
            margin: '10px 0',
            padding: '5px 20px',
            cursor: 'pointer',
            justifyContent: 'space-between'
          }}
        >
          <BodyText>{label}</BodyText>
          <StyledCircle isSelected={value === optionValue} />
        </div>
      ))}
    </div>
  )
}

RadioGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default memo(RadioGroup)
