import { phone, displayPhone } from '../utils/constants'

const airConditionerIcon =
  'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Ficons%2Fair-conditioner.svg?alt=media'
const parkingIcon =
  'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Ficons%2Fparking.svg?alt=media'
const petFriendlyIcon =
  'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Ficons%2Fpet-friendly.svg?alt=media'
const heaterIcon =
  'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Ficons%2Fheater.svg?alt=media'
const washerIcon =
  'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Ficons%2Fwasher.svg?alt=media'
const yardIcon =
  'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Ficons%2Fyard.svg?alt=media'
const benImage =
  'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fstory%2Fben.png?alt=media'

export const representative = {
  display_name: 'Ben Wong',
  first_name: 'Ben',
  last_name: 'Wong',
  phone,
  display_phone: displayPhone,
  email: 'ben@upandup.co',
  image_url: benImage
}

export const amenities = [
  {
    name: 'Central AC',
    icon_url: airConditionerIcon
  },
  {
    name: 'Garage',
    icon_url: parkingIcon
  },
  {
    name: 'Pets allowed',
    icon_url: petFriendlyIcon
  },
  {
    name: 'Heating',
    icon_url: heaterIcon
  },
  {
    name: 'Washer & Dryer',
    icon_url: washerIcon
  },
  {
    name: 'Large backyard',
    icon_url: yardIcon
  }
]

export default {
  2915: {
    id: 2915,
    config: {
      acquisition: {
        closingDate: '2018-10-06',
        purchasePrice: 549000,
        improvementCost: 5000,
        closingCostPercentage: -0.0005
      },
      expenses: {
        propertyTaxRate: 0.0127,
        insuranceRate: 0.0015
      },
      tenantTerms: {
        rent: 2850,
        occupancyDate: '2018-10-06',
        initialOptionPremium: 6160,
        targetMonthlyOptionPremium: 200
      }
    },
    images: [
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F1.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F2.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F3.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F4.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F5.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F6.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F7.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F8.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F9.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F10.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F11.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F12.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F13.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F14.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F15.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F16.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F17.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F18.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F19.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F20.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F21.jpg?alt=media',
      'https://firebasestorage.googleapis.com/v0/b/homevest-production.appspot.com/o/public%2Fimages%2F2915%2F22.jpg?alt=media'
    ],
    address: {
      displayLine1: '2025 W Vernon Ave',
      displayLine2: 'Los Angeles, CA 90062',
      line1: '2025 W Vernon Ave',
      line2: null,
      city: 'Los Angeles',
      state: 'CA',
      zip: '90062'
    },
    coordinates: {
      latitude: 34.003881,
      longitude: -118.316334
    },
    size: 1146,
    beds: 3,
    baths: {
      total: 2
    },
    date_available: '2018-10-01',
    description:
      'TURNKEY BUNGALOW IN DESIRABLE LEIMERT PARK NEIGHBORHOOD! This 3 bed 2 bath home is move-in ready! Beautifully upgraded throughout. Central A/C. Master bedroom with en suite master bathroom. Two bedrooms share full bathroom in hallway. Laundry space in hallway for new stackable washer/dryer. Granite countertops in kitchen and wood laminate flooring throughout. Large crown moldings and high baseboards. Drought-resistant landscaping in front yard and grass in back. Long driveway to detached 2-car garage can accommodate several cars or can be used for extra space for events. Walking distance to Leimert Park Plaza'
  }
}
